import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
//Simple bar
import SimpleBar from "simplebar-react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import {  getBuilderProject } from "../../store/builder/actions";
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash";
class ProjectList extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
   
    const { match: { params }, onGetBuilderProject} = this.props;
   
    onGetBuilderProject(params.id)
  }

  render() {
    const {  builderProjects } = this.props;
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar">
          <div id="sidebar-menu" className="setting-menu">
           
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to={"/pre-construction/builder" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
    
                </Link>
              </li>
              {map(builderProjects, (builderProject, prokey) => (
              <li key={prokey}>
                <Link to={"/pre-construction/bproject-detail/" + builderProject.projectBuilder +"/"+ builderProject.projectId} className="project-link">
                 
          
                  <span>{builderProject.projectName}</span>
                </Link>
              </li>
               ))}
             
             
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

ProjectList.propTypes = {
  t: PropTypes.any,
  match:PropTypes.object,
  onGetBuilderProject:PropTypes.func,
  builderProjects:PropTypes.array
}
const mapStateToProps = ({ Builder }) => ({
  builderProjects:Builder.builderProjects
});

const mapDispatchToProps = dispatch => ({
  onGetBuilderProject: id => dispatch(getBuilderProject(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectList)))
