import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {Container} from "reactstrap"
import { connect } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import LoadUnlayer from "../Email/unlayer-new";
import RsLink from "components/buttons/rsLink";
import { API_URL } from "helpers/app_url";

class projectMailEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName : '',
            design: {},
            id: '',           
            projectId: '',    
            templateId: ''  
        }
        this.handleChange = this.handleChange.bind(this);
        this.exportHtml = this.exportHtml.bind(this);
        this.LoadUnlayerRef = React.createRef();   
    }
    handleChange(event) {
        this.setState({ templateName: event.target.value });
      }

      componentDidMount() {
        const { match } = this.props;
        const { id, projectId, templateId } = match.params;

        this.setState({
            id,
            projectId,
            templateId
        });
        
    }
      exportHtml = () => {
        console.log('this.LoadUnlayerRef:', this.LoadUnlayerRef);
        console.log('this.LoadUnlayerRef.current:', this.LoadUnlayerRef.current);
        if (this.LoadUnlayerRef && this.LoadUnlayerRef.current) {
      
          this.LoadUnlayerRef.current.exportHtml((htmlContent) => {
            
            //console.log('HTML content:', htmlContent.design);
            const payload = {
              'agentId': parseInt(localStorage.getItem("userId")),
              'emailCampaignFullContent': JSON.stringify(htmlContent.design),
              'emailCampaignProjectId': parseInt(this.state.id),
              'emailCampaignTemplateId': parseInt(this.state.templateId),
              'emailCampaignTitle' : this.props.emailTemplateTitle,
              'emailCampaignContent' : htmlContent.html,
              'IsActive':true
            };
              const obj = JSON.parse(localStorage.getItem("authUser"));
              const token = obj.resultData.bearerToken;
              const str = 'Bearer ' + token;
              const headers = {
                  'Content-Type': 'application/json',
                  'Authorization': str
              }
              
              toast.loading("Please wait...");
              axios.post(`${API_URL}ProjectEmailCampaign`, payload, {
                  headers: headers
                })
                .then((response) => {
                    toast.dismiss();
                  
                    this.props.history.push('/pre-construction/mail-send/' + response.data.projectEmailCampaign.emailCampaignProjectId + '/' + response.data.projectEmailCampaign.projectEmailCampaignId);
                })
                .catch((error) => {
                 
                }) 
            });
           

        }
      };
    
    render() {
      const saveasdraft = () => {
        if (editorRef.current) {
          editorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            //console.log('exportHtml', html);
            const payload = {
              'agentId': parseInt(localStorage.getItem("userId")),
              'emailCampaignFullContent': JSON.stringify(design),
              'emailCampaignProjectId': parseInt(id),
              'emailCampaignTemplateId': parseInt(templateId),
              'emailCampaignTitle' : 'Draft',
              'emailCampaignContent' : html,
              'emailCampaignPublishStatus' : 'draft',
              'IsActive':true
            };
            // console.log('exportHtml', payload);
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const token = obj.resultData.bearerToken;
            const str = 'Bearer ' + token;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': str
            }
            toast.loading("Please wait...");
            axios.put(`${API_URL}ProjectEmailCampaign/updateProjectEmailCampaignSaveDraft`, payload, {
                    headers: headers
                  })
                  .then((response) => {
                    toast.dismiss();
                    toast.success("Email Saved as Draft");
                    history.push('/pre-construction/my-project');
                    
                  })
                  .catch((error) => {
                   
                  }) 
          });
        }
      };
      
      const sendPreview = () => {
        if (editorRef.current) {
          toast.loading("Please wait...");
          editorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            const payload = {
              'agentId': parseInt(localStorage.getItem("userId")),
              'emailCampaignFullContent': JSON.stringify(design),
              'emailCampaignProjectId': parseInt(id),
              'emailCampaignTemplateId': parseInt(templateId),
              'emailCampaignTitle' : 'Preview Email',
              'emailCampaignSubject' : 'Preview Email',
              'emailCampaignContent' : html,
              'emailCampaignCss' : '',
              //'emailCampaignPublishStatus' : 'draft',
              'IsActive':true
            };
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const token = obj.resultData.bearerToken;
            const str = 'Bearer ' + token;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': str
            }
            //toast.loading("Please wait...");
            axios.post(`${API_URL}ProjectEmailCampaign/previewprojectemailcampaign`, payload, {
                    headers: headers
                  })
                  .then((response) => {
                    //toast.dismiss();
                    toast.success("Preview Mail Send");
                    //history.push('/pre-construction/my-project');
                    
                  })
                  .catch((error) => {
                   
                  }) 
          });
        }
      };
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Email Editor | Realty Space</title>
                    </MetaTags>
                    <ToastContainer autoClose={2000} />
                    <Container fluid>
                        {/* Render Breadcrumb */}
                    <div>
                  
                    <span className="float-start mb20">
                        <RsLink className="btn btn-light btn-md" iconClass="bx bx-arrow-back" to={`/pre-construction/campaign/send-email/`+ this.props.match.params.projectId}>
                            Back
                        </RsLink>
                    </span>
                    <span className="float-end">
            <button
                type="button"
                className="btn btn-warning w-md me-2"
                
                onClick={saveasdraft}
            >
                                 Save as Draft
                                </button>{" "}
                                <button
                                  type="button"
                                  className="btn btn-success w-md me-2"
                                
                                  onClick={sendPreview}
                                >
                                  Send Preview Mail
                                </button>{" "}
            <button onClick={this.exportHtml}
              
              className="btn btn-primary w-md me-2"
             
            >
              Save & Continue
            </button>
            </span>
                    <LoadUnlayer  ref={this.LoadUnlayerRef} />
                    </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }



}
projectMailEditor.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    match:PropTypes.object,
    emailTemplateTitle:PropTypes.object

}
export default connect()(withTranslation()(projectMailEditor))