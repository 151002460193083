import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { ADD_BUILDER, ADD_BUILDER_DEPART, DELETE_BUILDER, GET_BUILDER, GET_BUILDER_DETAIL, GET_BUILDER_PROJECT, UPDATE_BUILDER } from "./actionTypes"
import { addSuccess,  builderapiError, getBuildersSuccess, getBuildersFail,getBuilderDetailFail, addDepartSuccess, apiDepartError, getBuilderDetailSuccess, deleteBuilderSuccess, deleteBuilderFail, getBuilderProjectSuccess, getBuilderProjectFail, updateBuilderError } from "./actions"

import { postBuilder, getBuilderdata, getBuilderDetail, deleteBuilderDetailApi, getBuilderProjectApi, updatetBuilderApi} from "../../helpers/backend_helper"

function* addBuilders({ payload: { builder, history } }) {
  try {
    
    //console.log(builder);
      const response = yield call(postBuilder, {
            builderName: builder.builderName,
            builderLogo: builder.builderLogo,
            fullAddress:builder.fullAddress,
            addressLine1: builder.addressLine1,
            addressLine2: builder.addressLine2,
            bAddressId: 0,
            city:builder.city,
            province:builder.province,
            postal:builder.postal,
            country:builder.country,
            bIsActive:true,
            agentId:parseInt(localStorage.getItem('userId')),
            builderLogoImage:null,
            builderLogoImageContent:null,
      })
        yield put(addSuccess(response))
        const res = yield call(getBuilderdata,localStorage.getItem('userId'))
        yield put(getBuildersSuccess(res))
        //history.push("/pre-construction/builder")
    }
catch (error) {

  yield put(builderapiError('Some Error'))
}
}

function* fetchBuilders({agentId}) {
  try {
    const response = yield call(getBuilderdata,agentId)
    yield put(getBuildersSuccess(response))
    
  } catch (error) {
    yield put(getBuildersFail(error))
  }
}

function* fetchBuilderDetail({ builderId }) {
  try {
    const response = yield call(getBuilderDetail, builderId)
    //console.log(response);
    yield put(getBuilderDetailSuccess(response))
  } catch (error) {
    yield put(getBuilderDetailFail(error))
  }
}


function* addDeparts({ payload: { depart, history } }) {
  console.log(depart)
  try {
    toast.loading("Please wait...")
      const response = yield call(postDepart, {
        builderId: depart.builderId,
        bContactDepartment: depart.bContactDepartment,
        phone: depart.phone,
        phoneExt: depart.phoneext,
        email: depart.email,
        mobile: depart.mobile,
        fax: depart.fax
           
      })
    yield put(addDepartSuccess(response))
       
    }
catch (error) {
  yield put(apiDepartError('Some Error'))
}
}


function* onDeleteBuilder({ payload: builder }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteBuilderDetailApi, builder.id)
    yield put(deleteBuilderSuccess(response))
    toast.dismiss();
    toast.success("Builder Deleted");
    const res = yield call(getBuilderdata, builder.agentId)
    yield put(getBuildersSuccess(res))

  } catch (error) {
    yield put(deleteBuilderFail(error))
  }
}

function* fetchBuilderProject({ payload : {builderId, agentId}}) {
  try {
    const response = yield call(getBuilderProjectApi,builderId, agentId)
    yield put(getBuilderProjectSuccess(response))
    
  } catch (error) {
    yield put(getBuilderProjectFail(error))
  }
}

function* onUpdateBuilder({ payload:  builder  }) {
  //console.log(addListing)
  try {
    toast.loading("Please wait...")
    const response = yield call(updatetBuilderApi,builder)
    //yield put(ClientAddListingUpdateSuccess(response))
    const res = yield call(getBuilderdata, builder.agentId)
    toast.dismiss();
    toast.success("Builder Updated");
    yield put(getBuildersSuccess(res))
  } catch (error) {
    yield put(updateBuilderError(error))
  }
}
function* builderSaga() {
  yield takeEvery(ADD_BUILDER, addBuilders)
  yield takeEvery(GET_BUILDER, fetchBuilders)
  yield takeEvery(GET_BUILDER_DETAIL, fetchBuilderDetail)
  yield takeEvery(ADD_BUILDER_DEPART, addDeparts)
  yield takeEvery(DELETE_BUILDER, onDeleteBuilder)
  yield takeEvery(GET_BUILDER_PROJECT, fetchBuilderProject)
  yield takeEvery(UPDATE_BUILDER, onUpdateBuilder)
}

export default builderSaga
