import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import classnames from "classnames"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { getProjectUUIDAction, getProjectWorksheetLead, getProjectWorksheetLeadUnitDetail, getProjectWorksheetLeadDocDetail,

   getProjectWorksheetLeadPurDetail,
  getProjectWorksheetUnitinfo
} from "../../../store/projects/actions"
import { addLeadNoteTab, getNoteLeadTab,  addFollowNoteTab, getFollowLeadTab, 
  getLeadCallLog, addLeadCallLog, addLeadEmailLog, getLeadEmailLog, getAllActLeadTab} from "../../../store/leads/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr"
import axios from "axios";
import JoditEditor from 'jodit-react';
import MaskedInput from "react-text-mask";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import notes from "../../../assets/images/rsicons/notes.png";
import follow from "../../../assets/images/rsicons/follow-up.png";
import phonelog from "../../../assets/images/rsicons/phone-log.png";
import emaillog from "../../../assets/images/rsicons/email-log.png";
import {
    Row,
    Col,
    CardBody,
    Card,
  
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
   Label,
   FormGroup,
   Input,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
  } from "reactstrap"
  import Moment from 'react-moment';
import { API_URL } from "helpers/app_url";
import { toast, ToastContainer } from "react-toastify";
import RsLink from "components/buttons/rsLink";
class projectClient extends Component {
    constructor(props) {
        super(props)
        this.state = {
          worksheetLead:'',
          activeTab1: "1",
          activeTab2: "4",
          activeTab: "1",
        }
        this.toggleViewDocument = this.toggleViewDocument.bind(this);   
        this.toggleUnitInformation = this.toggleUnitInformation.bind(this);
        this.toggleSubmittedCanvas = this.toggleSubmittedCanvas.bind(this);   
        //this.toggleUploadDocument = this.toggleUploadDocument.bind(this);  
        this.toggleFollowCanvas = this.toggleFollowCanvas.bind(this);
    this.toggleCallLogCanvas = this.toggleCallLogCanvas.bind(this);
    this.toggleEmailLogCanvas = this.toggleEmailLogCanvas.bind(this);
    this.toggleSmsLogCanvas = this.toggleSmsLogCanvas.bind(this);
    this.toggleAddNoteCanvas = this.toggleAddNoteCanvas.bind(this);    
    this.toggleMessageCanvas = this.toggleMessageCanvas.bind(this);  
    this.toggleAddLeadCanvas = this.toggleAddLeadCanvas.bind(this);
        this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
      }
      toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
          this.setState({
            activeTab1: tab,
          });
        }
      }
    
      toggle2(tab) {
        if (this.state.activeTab2 !== tab) {
          this.setState({
            activeTab2: tab,
          });
        }
      }
      docViewToggle() {
        this.setState({ isDocView: !this.state.isDocView });
      }
      unitViewToggle() {
        this.setState({ isUnitView: !this.state.isUnitView });
      }
      uploadDocToggle() {
        this.setState({ isUploadDoc: !this.state.isUploadDoc });
      }
      componentDidMount() {
        const { match: { params }, ongetProjectUUIDAction, onGetProjectWorksheetLead } = this.props;
        if (params && params.projectId) {
          ongetProjectUUIDAction(params.projectId);
          onGetProjectWorksheetLead(params.projectId,3);
        }
      }  
      toggleViewDocument() {
        this.setState({ worksheetLead: '', isDocView: !this.state.isDocView });
        this.docViewToggle();
      } 
      toggleViewDocument = arg => {
        const worksheetLead = arg;
       const { onGetProjectWorksheetLeadDocDetail } = this.props;
        this.setState({
          worksheetLead: {
            id: worksheetLead.id,
          }
        });
       onGetProjectWorksheetLeadDocDetail(worksheetLead.id);
        this.docViewToggle();
    }; 

    toggleUnitInformation() {
      this.setState({ worksheetLead: '', isUnitView: !this.state.isUnitView });
      this.unitViewToggle();
    } 
    toggleUnitInformation = arg => {
      const worksheetLead = arg;
     const { onGetProjectWorksheetLeadUnitDetail } = this.props;
      this.setState({
        worksheetLead: {
          id: worksheetLead.id,
          unitNote:worksheetLead.unitNote,
          unitStatusId:worksheetLead.unitStatusId,
          unitStatusText:worksheetLead.unitStatusText,
          projectLeadWorkSheetId:worksheetLead.projectLeadWorkSheetId

        }
      });
      onGetProjectWorksheetLeadUnitDetail(worksheetLead.id);
      this.unitViewToggle();
  }; 
  toggleSubmit() {

    this.setState({ isSubmittedData: !this.state.isSubmittedData });

  }
  toggleSubmittedCanvas() {
    this.setState({ worksheetLead: '', isSubmittedData: !this.state.isSubmittedData });
    this.toggleSubmit();
  }
  toggleSubmittedCanvas = arg => {
    const worksheetLead = arg;
    const { onGetProjectWorksheetLeadPurDetail, onGetProjectWorksheetLeadUnitDetail, onGetProjectWorksheetLeadDocDetail, onGetProjectWorksheetUnitinfo } = this.props;
    this.setState({
      worksheetLead: {
        id: worksheetLead.id,
        unitStatusId: worksheetLead.unitStatusId,
        projectCampaignLeadId: worksheetLead.projectCampaignLeadId,
      }
    });
    //console.log(worksheetLead);
    onGetProjectWorksheetLeadPurDetail(worksheetLead.id);
    onGetProjectWorksheetLeadUnitDetail(worksheetLead.id);
    onGetProjectWorksheetLeadDocDetail(worksheetLead.id);
    onGetProjectWorksheetUnitinfo(worksheetLead.id)
    this.toggleSubmit();
  };

  removeEmptySheetAllocationList = (data) => {
    return data.filter(item => item.insideSqFt !== '' || item.outsideSqFt !== '');
  }
  // Short Function
  toggle() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    }));
    
}
toggleFollow() {
  this.setState(prevState => ({
    isFollow: !prevState.isFollow
  }));
  
}
toggleAddNote() {
  this.setState(prevState => ({
    isAddNote: !prevState.isAddNote
  }));
  
}
toggleCallLog() {
  this.setState(prevState => ({
    isCallLog: !prevState.isCallLog
  }));
  
}
toggleWorkSheet() {
  this.setState({ isWorkSheet: !this.state.isWorkSheet });
}
toggleEmailLog() {
  this.setState(prevState => ({
    isEmailLog: !prevState.isEmailLog
  }));
  
}
toggleSmsLog() {
  this.setState(prevState => ({
    isSmsLog: !prevState.isSmsLog
  }));
  
}
toggleCanasEmail() {
  this.setState(prevState => ({
      isRight: !prevState.isRight
  }));
}
toggleEmailCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isRight: !this.state.isRight });
  this.toggleCanasEmail();
}
toggleAddNoteCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isFollow: !this.state.isAddNote });
  this.toggleAddNote();
}
toggleMessageCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isMessage: !this.state.isMessage });
  //this.toggleAddNote();
}
toggleFollowCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isFollow: !this.state.isFollow });
  this.toggleFollow();
}

toggleCallLogCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isCallLog: !this.state.isCallLog });
  this.toggleCallLog();
}

toggleEmailLogCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isEmailLog: !this.state.isEmailLog });
  this.toggleEmailLog();
}
toggleSmsLogCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isSmsLog: !this.state.isSmsLog });
  this.toggleSmsLog();
}
toggleOpenHouse() {
  this.setState(prevState => ({
    isEditOpenHouse: !prevState.isEditOpenHouse
  }));
}
  toggleOpenHouseCanvas() {
    this.setState({ isEditOpenHouseData: {}, isEditOpenHouse: !this.state.isEditOpenHouse });
    this.toggleOpenHouse();
  }

  toggleOpenHouseCanvas = arg => {
    this.setState({isEditOpenHouseData: arg});
    // onGetNoteLead(camplead.leadId);
    this.toggleOpenHouse();
};

  toggleAddNoteCanvas() {
    this.setState({ camplead: "", isEdit: false, isAddNote: !this.state.isAddNote });
    this.toggleAddNote();
  }


toggleFollowCanvas = arg => {
  const worksheetLead = arg;
  const { onGetFollowLead } = this.props;
  this.setState({
    worksheetLead: {
      leadId: worksheetLead.leadId,
  },
  isEditFollow: true,
  });
  onGetFollowLead(worksheetLead.leadId);
  this.toggleFollow();
};
toggleAddNoteCanvas = arg => {
  const worksheetLead = arg;
  const { onGetNoteLead } = this.props;
  this.setState({
    worksheetLead: {
    leadId: worksheetLead.leadId,
  },
  isEdit: true,
  });
  onGetNoteLead(worksheetLead.leadId);
  this.toggleAddNote();
};

toggleCallLogCanvas = arg => {
  const worksheetLead = arg;
  const { onGetLeadCallLog } = this.props;
  this.setState({
    worksheetLead: {
      leadId: worksheetLead.leadId,
  },
 
  });
  onGetLeadCallLog(worksheetLead.leadId);
  this.toggleCallLog();
};
toggleEmailLogCanvas = arg => {
  const worksheetLead = arg;
  const { onGetLeadEmailLog } = this.props;
  this.setState({
    worksheetLead: {
    leadId: worksheetLead.leadId,
  },
  isEditCall: true,
  });
  onGetLeadEmailLog(worksheetLead.leadId);
  this.toggleEmailLog();
};
toggleSmsLogCanvas = arg => {
  const worksheetLead = arg;
  this.setState({
    worksheetLead: {
      leadId: worksheetLead.id
      },
      
    
  });
  this.toggleSmsLog();
};
  toggleAddLeadCanvas() {
    this.setState({  isAddLead: !this.state.isAddLead });
    //this.toggleAddLead();
  }
  toggleEmailCanvas = arg => {
    //alert('Hi');
    const worksheetLead = arg;
    const { onGetAllActLead } = this.props;
    this.setState({
      worksheetLead: {
        leadId: worksheetLead.leadId,
      },
        isEdit: true,
    });
    onGetAllActLead(worksheetLead.leadId)
    this.toggleCanasEmail();
}; 

      render() {
        const { projectData, worksheetLeads,  onGetProjectWorksheetLead, worksheetPurData, worksheetUnitData, worksheetDocData, unitdata, activities, onAddLeadNote, onAddLeadFollowUp, onAddCallLog, onAddEmailLog  } = this.props;
        const { leadNotes, leadFollows, calllogs, emaillogs,  } = this.props;
    const { isEdit, isEditFollow, isEditCall, lead } = this.state;
        const worksheetLead = this.state.worksheetLead;
       console.log(worksheetLead);
        return (
          <React.Fragment>
              <div className="page-content project-page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <ToastContainer autoClose={2000} />
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Activity")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
         
            <Row>
                   <Col lg="12">
                   <Nav tabs className="projectTab projectNav">
                   
                      <NavItem>
                      <Link className="nav-link " to={"/pre-construction/campaign/current-prospects/"  + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to={"/pre-construction/campaign/srr-filled/"  + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/campaign/client/"  + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                      {/* <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/marketing/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          
                        </Link>
                      </NavItem> */}
                      {/* <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/setting/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Settings")}</span>
                          
                        </Link>
                      </NavItem> */}
                    </Nav>
                      
                    </Col>  
                             
                </Row> 
                <Row>
                   
                    <Col lg="12">
                    
                        <Card>
                        <CardBody>
                       
                       
                                <div className="table-rep-plugin">
                                                <div
                                                    className=""
                                                    data-pattern="priority-columns"
                                                >
                                                    <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped table-bordered"
                                                    >
                                                    <Thead>
                                                        <Tr>
                                                        <Th>{this.props.t("Name")}</Th>
                                                        <Th data-priority="1">{this.props.t("Date")}</Th>
                                                        <Th data-priority="3">{this.props.t("Email")}</Th>
                                                        <Th data-priority="3">{this.props.t("Phone")}</Th>
                                                        <Th data-priority="3">{this.props.t("Action")}</Th>
                                                    
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                    {map(worksheetLeads, (worksheetLead, prokey) => (
                                                        <Tr>
                                                        <Th>
                                                        <div className="row">
                                                        <div className="col-sm-8" >{worksheetLead.name}</div>
                                                               <div className="col-sm-1"><Link onClick={() => this.toggleFollowCanvas(worksheetLead)} href="#"><i className="grid-icon bx bx-user-voice"></i></Link></div>
                                                                  <div className="col-sm-1"><Link onClick={() => this.toggleEmailCanvas(worksheetLead)} to="#"><i className="grid-icon bx  bx-timer"></i></Link></div>
                                                                  <div className="col-sm-1"><Link onClick={this.toggleMessageCanvas} to="#"><i className="grid-icon  bx bx-message-alt"></i></Link></div>
                                                                  <div className="col-sm-1">
                                                                  <UncontrolledDropdown>
                                                                        <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                        <DropdownItem onClick={() => this.toggleAddNoteCanvas(worksheetLead)} href="#"><i className="bx bx-user-voice"></i> Add Note</DropdownItem>
                                                                          <DropdownItem onClick={() => this.toggleCallLogCanvas(worksheetLead)} href="#"><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                                                          <DropdownItem onClick={() => this.toggleEmailLogCanvas(worksheetLead)} href="#"><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>
                                                                          {/*  <DropdownItem onClick={() => this.toggleSmsLogCanvas(data)} href="#"><i className=" bx bx-message-dots"></i> Add SMS log</DropdownItem> */}
                                                                          {/* <DropdownItem onClick={() => this.toggleFollowCanvas(data)} href="#"><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem> */}
                                                                        </DropdownMenu>
                                                                      </UncontrolledDropdown>
                                                                  
                                                                  </div>


                                                            </div>
                                                        </Th>
                                                        <Td>{worksheetLead.filledDate ? <Moment format="D MMM YY">{worksheetLead.filledDate}</Moment> : '' }</Td>
                                                        <Td>{worksheetLead.email}  </Td>
                                                        <Td> {worksheetLead.cellPhone} </Td>
                                                        <Td> <RsLink className="btn btn-action" iconClass="" onClick={() => this.toggleSubmittedCanvas(worksheetLead)}>Manage</RsLink>
                                                        {/* <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem tag={Link} onClick={() => this.toggleViewDocument(worksheetLead)}>View Document</DropdownItem>
                                       
                                     
                                        <DropdownItem  href="#"  onClick={() => this.toggleUnitInformation(worksheetLead)}>Unit Information</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                                            
                                                            </Td>

                                                        </Tr>
                                                    ))}
                                                        
                                                    </Tbody>
                                                    </Table>
                                                </div>
                                                </div>
                           
                               
                               
                                
                               
                               
                              
                           
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Col>
                </Row>
                </Container>
                <Offcanvas
              isOpen={this.state.isSubmittedData}
              direction="end"
              toggle={this.toggleSubmittedCanvas}
              style={{ width: 600 }}

            >
              <OffcanvasHeader className="submittedWorksheet" toggle={this.toggleSubmittedCanvas}>
                Submitted Worksheet <span className="float-end"><i className=" bx bx-printer"></i></span>
              </OffcanvasHeader>
              <OffcanvasBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "1",
                      })}
                      onClick={() => {
                        this.toggle1("1");
                      }}
                    >
                      Applicant Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "2",
                      })}
                      onClick={() => {
                        this.toggle1("2");
                      }}
                    >
                      Unit Choices
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "4",
                      })}
                      onClick={() => {
                        this.toggle1("4");
                      }}
                    >
                      Unit Status
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "3",
                      })}
                      onClick={() => {
                        this.toggle1("3");
                      }}
                    >
                      Documents
                    </NavLink>
                  </NavItem>

                </Nav>
                <TabContent
                  activeTab={this.state.activeTab1}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <div className="tabContent">
                      {map(worksheetPurData, (purData, purkey) => (
                        <>
                          <h5>Purchaser {purkey + 1}</h5>
                          <div className="table-responsive">
                            <Table className="table table-sm m-0">

                              <tbody>
                                <tr>
                                  <th scope="row">Name</th>
                                  <td>{purData.firstName + " " + purData.lastName}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Address</th>
                                  <td>{purData.address}</td>
                                </tr>
                                <tr>
                                  <th scope="row">City</th>
                                  <td>{purData.city}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Province</th>
                                  <td>{purData.province}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Phone</th>
                                  <td>{purData.homePhone}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Cell</th>
                                  <td>{purData.cellPhone}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Email</th>
                                  <td>{purData.email}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Birth Date</th>
                                  <td>{purData.birthDate ? <Moment format="D MMM YY">{purData.birthDate}</Moment> : ''}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Drivers License</th>
                                  <td>{purData.driversLicense}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Occupation</th>
                                  <td>{purData.occupation}</td>
                                </tr>

                              </tbody>
                            </Table>
                          </div>
                          <p>{" "}</p>
                        </>
                      ))}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="tabContent">
                      <div className="table-responsive">
                        <Table className="table table-sm m-0">

                          <tbody>
                            {map(worksheetUnitData, (unitData, unitkey) => (
                              <>
                                <tr>
                                  <th scope="row">Choice {unitkey + 1}</th>
                                  <td>Model: {unitData.optionNumber} {" "} Type: {unitData.optionType}</td>


                                </tr>
                                <tr>
                                  <td colSpan="2">Notes: {unitData.optionNotes}</td>
                                </tr>
                              </>
                            ))}


                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tabId="3">
                    <div className="tabContent">
                      <div className="table-responsive">


                        <h5>Purchaser</h5>
                        <Table className="table table-sm m-0">

                          <tbody>
                            {map(worksheetDocData, (docData, dockey) => (
                              <tr>
                                <th scope="row">{dockey + 1}</th>
                                <td>{docData.documentTitle}</td>
                                <td><a target="_blank" rel="noreferrer" href={docData.documentURL}>View</a></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <p>{" "}</p>



                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <TabPane tabId="4">
                      <div className="tabContent">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            updateViaEmail: unitdata?.updateViaEmail ? true : false,
                            depositPaymentReceived:unitdata?.depositPaymentReceived ? true : false,
                            paymentInfo:unitdata?.paymentInfo ? unitdata.paymentInfo : '',
                            emailContent:unitdata?.emailContent ? unitdata.emailContent : '',
                            id: worksheetLead.id,
                            projectLeadWorkSheetId: worksheetLead.id,
                            unitStatusId: (unitdata?.unitStatusId) ? unitdata.unitStatusId : 1,
                            unitStatusText: (unitdata?.unitStatusText) ? unitdata.unitStatusText : 'Waiting for allocations',
                            unitNote: (unitdata?.unitNote) ? unitdata.unitNote : '',
                            allocationBy: parseInt(localStorage.getItem('userId')),
                            createProjectLeadWorkSheetAllocationList: worksheetUnitData?.map((item) => ({
                              projectLeadWorkSheetId: worksheetLead.id,
                              selectedOptionId: item?.isAllocated ? item?.id : null,
                              selectedOptionText: item?.optionNumber
                                ? `${item.optionNumber}/${item.optionType}`
                                : "",
                              unitCost: item?.unitCost || "", 
                              insideSqFt: item?.insideSqFt || "", 
                              outsideSqFt: item?.outsideSqFt || "", 
                            })) ||  []
                            
                          }}
                          validationSchema={Yup.object().shape({
                            // createProjectLeadWorkSheetAllocationList: Yup.array().of(
                            //   Yup.object().shape({
                            //     selectedOptionId: Yup.string().required("Option ID is required"),
                            //     insideSqFt: Yup.number()
                            //       .typeError("Must be a number")
                            //       .when("selectedOptionId", {
                            //         is: (val) => !!val,
                            //         then: Yup.number().required("Inside sq. ft. is required"),
                            //       }),
                            //     outsideSqFt: Yup.number()
                            //       .typeError("Must be a number")
                            //       .when("selectedOptionId", {
                            //         is: (val) => !!val,
                            //         then: Yup.number().required("Outside sq. ft. is required"),
                            //       }),
                            //     unitCost: Yup.number()
                            //       .typeError("Must be a number")
                            //       .when("selectedOptionId", {
                            //         is: (val) => !!val,
                            //         then: Yup.number().required("Cost is required"),
                            //       }),
                            //   })
                            // ),
                          })}

                          onSubmit={(values) => {
                            const filteredList = this.removeEmptySheetAllocationList(values.createProjectLeadWorkSheetAllocationList);
                            const convertedList = filteredList.map(item => ({
                              ...item,
                              unitCost: parseInt(item.unitCost, 10) || 0,
                              insideSqFt: parseInt(item.insideSqFt, 10) || 0,
                              outsideSqFt: parseInt(item.outsideSqFt, 10) || 0,
                            }));

                            const allocationData = {
                              ...values,
                              createProjectLeadWorkSheetAllocationList: convertedList,
                            };
                            const obj = JSON.parse(localStorage.getItem("authUser"));
                            const token = obj.resultData.bearerToken;
                            const str = 'Bearer ' + token;
                            const headers = {
                              'Content-Type': 'application/json',
                              'Authorization': str
                            };

                            toast.loading("Please wait...");
                            axios.post('https://rscoreapi.azurewebsites.net/api/ProjectLeadWorkSheet/AddProjectLeadWorkSheetAllocation', allocationData, { headers })
                              .then(response => {
                                toast.dismiss();
                                if (response.data.success) {
                                  toast.success("Unit Data Submitted");
                                  onGetProjectWorksheetLead(this.props.match.params.projectId, 3);
                                  this.toggleSubmit();
                                } else {
                                  toast.warn(response.validationErrors[0]);
                                }
                              })
                              .catch(error => {
                                toast.dismiss();
                                console.error('Error:', error);
                              });
                          }}
                        >
                          {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                            <Form
                              className="needs-validation"
                            >
                              <Row>

                               
                           
                                <Col md="12">
                                  <FormGroup className="mb-3">


                                    <Input
                                      type="radio"
                                      id="customRadioInline3"
                                      name="unitStatusId"
                                      className="form-check-input mt-2"
                                      value="3"
                                      checked={values.unitStatusId === 3}
                                      onChange={(e) => {
                                        setFieldValue('unitStatusId', 3);
                                        setFieldValue('unitStatusText', 'Unit Allocated');
                                      }}
                                    />
                                    <Label
                                      className="form-check-label ms-4"
                                      htmlFor="customRadioInline3"
                                    >
                                      <span className="">Unit Allocated</span>
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3">


                                    <Input
                                      type="radio"
                                      id="customRadioInline4"
                                      name="unitStatusId"
                                      className="form-check-input mt-2"
                                      value="4"
                                      checked={values.unitStatusId === 4}
                                      onChange={(e) => {
                                        setFieldValue('unitStatusId', 4);
                                        setFieldValue('unitStatusText', 'Withdrawn from client');
                                      }}
                                    />
                                    <Label
                                      className="form-check-label ms-4"
                                      htmlFor="customRadioInline4"
                                    >
                                      Withdrawn from client
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {values.unitStatusId !== 1 && (
                                <>
                                  <hr></hr>
                                  {values.unitStatusId !== 2 && values.unitStatusId !== 4 && (
                                    <>
                                      <div id="Selchoice">
                                        <FieldArray name="createProjectLeadWorkSheetAllocationList">
                                          {({ insert, remove, push }) => (
                                            <>
                                              {map(worksheetUnitData, (unitData, unitkey) => (
                                                <Row key={unitkey}>
                                                  <Col md="12">
                                                    <div className="form-check mb-3">
                                                      <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                        //value={unitData.id}
                                                        id={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                        onChange={(e) => {
                                                          const isChecked = e.target.checked;
                                                          const newValue = isChecked ? unitData.id : null;
                                                          const optionText = isChecked ? `${unitData.optionNumber}/${unitData.optionType}` : '';

                                                          setFieldValue(`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`, newValue);
                                                          setFieldValue(`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionText`, optionText);
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        id={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                      >
                                                        {unitData.optionNumber}/{unitData.optionType}
                                                      </label>
                                                    </div>

                                                  </Col>
                                                  <Col md="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}>
                                                        {this.props.t("Inside sq. ft.")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}
                                                        type="text"
                                                        className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey].insideSqFt &&
                                                            touched.createProjectLeadWorkSheetAllocationList &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey].insideSqFt
                                                            ? " is-invalid"
                                                            : ""
                                                          }`}
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}>
                                                        {this.props.t("Outside sq.ft")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}
                                                        type="text"
                                                        onChange={handleChange}
                                                        className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey].outsideSqFt &&
                                                            touched.createProjectLeadWorkSheetAllocationList &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey].outsideSqFt
                                                            ? " is-invalid"
                                                            : ""
                                                          }`}
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}>
                                                        {this.props.t("Cost")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}
                                                        type="text"
                                                        onChange={handleChange}
                                                        className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey].unitCost &&
                                                            touched.createProjectLeadWorkSheetAllocationList &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey].unitCost
                                                            ? " is-invalid"
                                                            : ""
                                                          }`}
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                </Row>


                                              ))}
                                            </>
                                          )}
                                        </FieldArray>
                                      </div>
                                      <Row>
                                        <Col sm="12">
                                          <div className="form-check mb-3">
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="depositPaymentReceived"
                                              id="depositPaymentReceived"
                                            />
                                            {" "} <label htmlFor="depositPaymentReceived" className="form-check-label">Deposit payment received</label>
                                          </div>
                                        </Col>
                                        {values.depositPaymentReceived && (
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="paymentInfo">
                                                {this.props.t("Payment info")}
                                              </Label>
                                              <Field
                                                name="paymentInfo"
                                                onChange={handleChange}
                                                as="textarea"
                                                className={
                                                  "form-control" +
                                                  (errors.paymentInfo && touched.paymentInfo
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="paymentInfo"
                                              />
                                              <ErrorMessage
                                                name="paymentInfo"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        )}
                                      </Row>
                                    </>
                                  )}
                                  <Row>
                                    <Col sm="12">
                                      <div className="form-check mb-3">
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="updateViaEmail"
                                          id="updateViaEmail"
                                        />
                                        {" "} <label htmlFor="updateViaEmail" className="form-check-label">Provide update via email</label>
                                      </div>
                                    </Col>
                                    {values.updateViaEmail && (
                                      <Col md="12">
                                        <JoditEditor
                                          value={values.emailContent}
                                          onBlur={(newContent) => {
                                            setFieldValue('emailContent', newContent);
                                          }}
                                          config={{
                                            readonly: false,
                                            placeholder: "Start typing here...",
                                            height: 400,

                                          }}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                  <Row id="noteRow">
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="unitNote">
                                          {this.props.t("Add Note")}
                                        </Label>
                                        <Field
                                          name="unitNote"
                                          onChange={handleChange}
                                          as="textarea"
                                          className={
                                            "form-control" +
                                            (errors.unitNote && touched.unitNote
                                              ? " is-invalid"
                                              : "")
                                          }
                                          id="unitNote"
                                        />
                                        <ErrorMessage
                                          name="unitNote"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>
                            </Form>
                          )}
                        </Formik>


                      </div>
                    </TabPane>
                  </TabPane>
                </TabContent>
                


              </OffcanvasBody>
            </Offcanvas>
                <Offcanvas
                            isOpen={this.state.isDocView}
                            direction="end"
                            toggle={this.toggleViewDocument}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleViewDocument}>
                                View Documents
                            </OffcanvasHeader>
                            <OffcanvasBody>
                          
                          <div className="table-responsive">
                          
                      <Table className="table table-sm m-0">
                      
                        <tbody>
                        {map(worksheetDocData, (docData, dockey) => (
                          <tr>
                            <th scope="row">{dockey+1}</th>
                            <td>{docData.documentTitle}</td>
                            <td><a target="_blank" rel="noreferrer" href={docData.documentURL}>View</a></td>
                          </tr>
                          ))} 
                          </tbody>
                          </Table>
                          <p>{" "}</p>
                          
                                                     
            
                    </div>
                          
                     
                              </OffcanvasBody>
                            </Offcanvas>

                            <Offcanvas
                            isOpen={this.state.isUnitView}
                            direction="end"
                            toggle={this.toggleUnitInformation}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleUnitInformation}>
                                View Units
                            </OffcanvasHeader>
                            <OffcanvasBody>
                          
                           
                      <Table className="table table-sm m-0">
                        
                        <tbody>
                        {map(worksheetUnitData, (unitData, unitkey) => (
                          <>
                          <tr>
                            <th scope="row">Choice {unitkey+1}</th>
                            <td>Model: {unitData.optionNumber} {" "} Type: {unitData.optionType}</td>
                            

                          </tr>
                          <tr>
                            <td colSpan="2">Notes: {unitData.optionNotes}</td>
                          </tr>
                          </>
                        ))}
                          
                          
                        </tbody>
                      </Table>
                      <div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: worksheetLead.id,
                   
                    unitStatusId: worksheetLead.unitStatusId,
                    unitStatusText:'Unit Allocated',
                    unitNote:worksheetLead.unitNote,
                    projectLeadWorkSheetId:worksheetLead.projectLeadWorkSheetId,
                    allocationBy: parseInt(localStorage.getItem('userId')),
                   
                  }}
              
                  onSubmit={(values) => {                    
                    const allocationData = {
                      id: parseInt(values.id),
                      projectLeadWorkSheetId:parseInt(values.id),
                      unitStatusId: parseInt(values.unitStatusId),
                      unitStatusText:values.unitStatusText,
                      unitNote:values.unitNote,
                      allocationBy: parseInt(values.allocationBy),
                      createProjectLeadWorkSheetAllocationList:[]
                    };
                    const obj = JSON.parse(localStorage.getItem("authUser"));
                    const token = obj.resultData.bearerToken;
                    const str = 'Bearer ' + token;
                    const headers = {
                      'Content-Type': 'application/json',
                      'Authorization': str
                    };

                    toast.loading("Please wait...");
                    axios.post(`${API_URL}ProjectLeadWorkSheet/AddProjectLeadWorkSheetAllocation`, allocationData, { headers })
                      .then(response => {
                        toast.dismiss();
                        if (response.data.success) {
                          toast.success("Unit Data Submitted");
                          onGetProjectWorksheetLead(this.props.match.params.projectId,3);
                          console.log('successful:', response.data);
                          this.unitViewToggle();
                        } else {
                          toast.warn(response.validationErrors[0]);
                        }
                      })
                      .catch(error => {
                        toast.dismiss();
                        console.error('Error:', error);
                      });
                  }}
                >
                  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">


                            <Input
                              type="radio"
                              id="customRadioInline3"
                              name="unitStatusId"
                              className="form-check-input mt-2"
                              value="3"
                              checked={values.unitStatusId === 3}
                              onChange={(e) => {
                                setFieldValue('unitStatusId', 3);
                                setFieldValue('unitStatusText', 'Unit Allocated');
                              }}
                            />
                            <Label
                              className="form-check-label ms-4"
                              htmlFor="customRadioInline3"
                            >
                              <span className="">Unit Allocated</span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Input
                              type="radio"
                              id="customRadioInline4"
                              name="unitStatusId"
                              className="form-check-input mt-2"
                              value="4"
                              checked={values.unitStatusId === 4}
                              onChange={(e) => {
                                setFieldValue('unitStatusId', 4);
                                setFieldValue('unitStatusText', 'Withdrawn from client');
                              }}
                            />
                            <Label
                              className="form-check-label ms-4"
                              htmlFor="customRadioInline4"
                            >
                              Withdrawn from client
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row id="noteRow">
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="unitNote">
                              {this.props.t("Add Note")}
                            </Label>
                            <Field
                              name="unitNote"
                              onChange={handleChange}
                              as="textarea"
                              className={
                                "form-control" +
                                (errors.unitNote && touched.unitNote
                                  ? " is-invalid"
                                  : "")
                              }
                              id="unitNote"
                            />
                            <ErrorMessage
                              name="unitNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>
                    </Form>
                  )}
                </Formik>
              </div>
                          
                     
                              </OffcanvasBody>
                            </Offcanvas>


                            <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleEmailCanvas}
          
        >
          <OffcanvasHeader toggle={this.toggleEmailCanvas}>
            All Activities
          </OffcanvasHeader>
          <OffcanvasBody>
          <ul className="verti-timeline list-unstyled">
                                        {map(
                                          activities,
                                          (allactivity, allactkey) => (
                                            <li
                                              className="event-list eventlist"
                                              key={"_allact_" + allactkey}
                                            >
                                              <div className="event-timeline-dot">
                                                {(() => {
                                                  if (
                                                    allactivity.activitySubType ==
                                                      8 ||
                                                    allactivity.activitySubType ==
                                                      9 ||
                                                    allactivity.activitySubType ==
                                                      7
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={emaillog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                      3 ||
                                                    allactivity.activitySubType ==
                                                      4 ||
                                                    allactivity.activitySubType ==
                                                      5
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={phonelog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                    10
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={follow} />
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <img src={notes} />
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                  <h5 className="font-size-14">
                                                    <Moment format="D MMM YY">
                                                      {allactivity.addedDate}
                                                    </Moment>
                                                  </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <h5 className="font-size-14">
                                                    {
                                                      localization[
                                                        "ActivitySubType_" +
                                                          allactivity.activityType +
                                                          "_" +
                                                          allactivity.activitySubType
                                                      ]
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="exNote">
                                                {allactivity.activityRemarks}
                                              </div>
                                            </li>
                                          )
                                        )}
          </ul>
          </OffcanvasBody>
        </Offcanvas>

     
        <Offcanvas
          isOpen={this.state.isAddNote}
          direction="end"
          toggle={this.toggleAddNoteCanvas}
        >
          <OffcanvasHeader toggle={this.toggleAddNoteCanvas}>
            Add Notes
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: worksheetLead.leadId,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                   
                  onAddLeadNote(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleAddNote();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Add Note")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>
      
        <Offcanvas
          isOpen={this.state.isCallLog}
          direction="end"
          toggle={this.toggleCallLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleCallLogCanvas}>
            Call Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: worksheetLead.leadId


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddCallLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleCallLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />  

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="callNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>               
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isFollow}
          direction="end"
          toggle={this.toggleFollowCanvas}

        >
          <OffcanvasHeader toggle={this.toggleFollowCanvas}>
            Follow Us
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                refId: worksheetLead.leadId,
                addNote: (this.state && this.state.addNote) || "",
                nextFollowupDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEditFollow) {
                   //console.log(values)
                  onAddLeadFollowUp(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleFollow();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">

                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb15">

                        <Label htmlFor="logDate">
                          Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.nextFollowupDate && touched.nextFollowupDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.nextFollowupDate}
                          onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (follLog, follLogkey) => (

                    <li className="event-list eventlist" key={"_note_" + follLogkey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{follLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{follLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                          
            
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isEmailLog}
          direction="end"
          toggle={this.toggleEmailLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailLogCanvas}>
            Email Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType:'',
                addedDate: new Date(),
                refId: worksheetLead.leadId  

              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddEmailLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleEmailLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent" onChange={handleChange} />
                          Email Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Received" onChange={handleChange} />
                          Email Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced" onChange={handleChange} />
                          Email Bounced
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="remarks">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="remarks"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="remarks"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emaillogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isSmsLog}
          direction="end"
          toggle={this.toggleSmsLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleSmsLogCanvas}>
            SMS Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                logDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={this.handleLeadEmailSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="SMS Sent" onChange={handleChange} />
                          SMS Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Received" onChange={handleChange} />
                          SMS Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Bounced" onChange={handleChange} />
                          SMS Bounced
                        </label>

                      </div>

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="logDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.logDate && touched.logDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.logDate}
                          onChange={(value) => setFieldValue('logDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="smsNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="smsNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="smsNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
   <Offcanvas
          isOpen={this.state.isMessage}
          direction="end"
          toggle={this.toggleMessageCanvas}
        >
          <OffcanvasHeader toggle={this.toggleMessageCanvas}>
            Message
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                //refId: lead.leadId,
                addMessage: (this.state && this.state.addMessage) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addMessage: Yup.string().required("This is Required")
              })}

              onSubmit=""
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Send Message")}
                        </Label>
                        <textarea
                          name="addMessage"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addMessage && touched.addMessage
                              ? " is-invalid"
                              : "")
                          }
                          id="addMessage"
                        />
                        <ErrorMessage
                          name="addMessage"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Send")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Message History</div>
                              
          </OffcanvasBody>
        </Offcanvas>

            </div>
          </React.Fragment>
        )
      }
}

projectClient.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    className: PropTypes.any,
    worksheetLeads:PropTypes.array,
    onGetProjectWorksheetLead:PropTypes.func,
    onGetProjectWorksheetLeadDocDetail:PropTypes.func,
    onGetProjectWorksheetLeadUnitDetail:PropTypes.func,
    onGetProjectWorksheetLeadPurDetail: PropTypes.func,
    worksheetUnitData:PropTypes.array,
    worksheetDocData:PropTypes.array,
    onGetProjectWorksheetUnitinfo: PropTypes.func,
    unitdata: PropTypes.object,
    worksheetPurData: PropTypes.array,

    onGetAllActLead: PropTypes.func,
    activities: PropTypes.array,
    onAddLeadNote: PropTypes.func,
    onGetNoteLead:PropTypes.func,
    leadNotes:PropTypes.array,
    onGetFollowLead:PropTypes.func,
    leadFollows:PropTypes.array,
    onAddLeadFollowUp:PropTypes.func,
    onGetLeadEmailLog:PropTypes.func,
    onGetLeadCallLog:PropTypes.func,
    calllogs:PropTypes.array,
    emaillogs:PropTypes.array,
    onAddCallLog:PropTypes.func,
    onAddEmailLog:PropTypes.func

  }
  const mapStateToProps = ({ Project, leads }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading,
      worksheetLeads:Project.worksheetLeads,
      worksheetUnitData:Project.worksheetUnitData,
      worksheetPurData: Project.worksheetPurData,
      worksheetDocData:Project.worksheetDocData,
      unitdata: Project.unitdata,
      activities: leads.activities,
      unitdata: Project.unitdata,
      leadNotes: leads.leadNotes,
      leadFollows: leads.leadFollows,
      calllogs:leads.calllogs,
      emaillogs:leads.emaillogs,
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    onGetProjectWorksheetLead:(projectId, statusId) => dispatch(getProjectWorksheetLead(projectId, statusId)),
    onGetProjectWorksheetLeadUnitDetail:(id) => dispatch(getProjectWorksheetLeadUnitDetail(id)),
    onGetProjectWorksheetLeadDocDetail:(id) => dispatch(getProjectWorksheetLeadDocDetail(id)),
    onGetProjectWorksheetUnitinfo: (id) => dispatch(getProjectWorksheetUnitinfo(id)),
    onGetProjectWorksheetLeadPurDetail: (id) => dispatch(getProjectWorksheetLeadPurDetail(id)),


    onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
      onAddLeadNote: (data) => dispatch(addLeadNoteTab(data)),
      onGetNoteLead: (leadId) => dispatch(getNoteLeadTab(leadId)),
      onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
      onAddLeadFollowUp:(data) => dispatch(addFollowNoteTab(data)),
      onAddCallLog:(data) => dispatch(addLeadCallLog(data)),
      onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
      onAddEmailLog:(data) => dispatch(addLeadEmailLog(data)),
      onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
      onGetAllActLead: (leadId) => dispatch(getAllActLeadTab(leadId)),
  });
export default connect(mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(projectClient)))
