import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';

const UnlayerPageBuilder = forwardRef(({ design }, ref) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://editor.unlayer.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.unlayer.init({
        id: 'editor',
        projectId: 228097,
        templateId:584851,
        displayMode: 'web',
        tools: {
          social: {
            enabled: false
          }
        },
        features: {
          audit: true
        },
        saveDesign: (data) => {
         
          editorRef.current = data;
        }
      });
    };
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  

  const exportHtml = (callback) => {
    if (window.unlayer) {
      window.unlayer.exportHtml((data) => {
        if (callback) {
          callback(data);
        }
      });
    }
  };
  const saveDesign = () => {
    try {
      if (window.unlayer && editorRef.current) {
        const designState = window.unlayer.saveDesign();
        return designState;
      }
      return null;
    } catch (error) {
      console.error('Error saving design:', error);
      return null;
    }
  };
 
  useEffect(() => {
    if (ref) {
      ref.current = {
        exportHtml: exportHtml,
        saveDesign: saveDesign
      };
    }
  }, [ref, exportHtml, saveDesign]);

  return (
    <div>
      <div id="editor" style={{ height: '800px' }} />
    </div>
  );
});

UnlayerPageBuilder.displayName = 'UnlayerPageBuilder';

UnlayerPageBuilder.propTypes = {
  design: PropTypes.shape({
    html: PropTypes.string.isRequired
  }),
};

export default UnlayerPageBuilder;
