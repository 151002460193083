import { UPDATE_PROJECT_STATUS, UPDATE_PROJECT_STATUS_FAIL } from "store/settings/Appointment/actionTypes"
import {
  GET_PROJECT_TYPE_SUCCESS,
  GET_PROJECT_TYPE_FAIL,
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_ERROR,
  GET_ALL_PROJECT_SUCCESS,
  GET_ALL_PROJECT_FAIL,
  GET_PROJECT_CONTACT_SUCCESS,
  GET_PROJECT_CONTACT_FAIL,
  GET_PROJECT_MEDIA_SUCCESS,
  GET_PROJECT_MARKET_SUCCESS,
  GET_PROJECT_MARKET_ERROR,
  GET_ALL_PROJECT_UUID_SUCCESS,
  GET_ALL_PROJECT_UUID_FAIL,
  GET_PROJECT_FEATURE_SUCCESS,
  GET_PROJECT_FEATURE_ERROR,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_CONTACT_SUCCESS,
  UPDATE_PROJECT_CONTACT_FAIL,
  UPDATE_PROJECT_FEATURE_SUCCESS,
  UPDATE_PROJECT_FEATURE_ERROR,
  DELETE_PROJECT_CONTACT_SUCCESS,
  DELETE_PROJECT_CONTACT_FAIL,
  UPDATE_PROJECT_MARKET_SUCCESS,
  UPDATE_PROJECT_MARKET_ERROR,
  DELETE_PROJECT_MARKET_SUCCESS,
  DELETE_PROJECT_MARKET_FAIL,
  DELETE_PROJECT_LINK_SUCCESS,
  DELETE_PROJECT_LINK_FAIL,
  GET_PROJECT_LINK_SUCCESS,
  GET_PROJECT_LINK_ERROR,
  GET_PROJECT_GALLERY_SUCCESS,
  GET_PROJECT_GALLERY_ERROR,
  DELETE_PROJECT_GALLERY_SUCCESS,
  DELETE_PROJECT_GALLERY_FAIL,
  GET_ALL_PROJECT_STATUS_SUCCESS,
  GET_ALL_PROJECT_STATUS_FAIL,
  GET_ALL_PROJECT_UUID,
  GET_ALL_PROJECT,
  SET_PROJECT_SEARCH_YEAR,
  SET_PROJECT_SEARCH_CITY,
  SET_PROJECT_SEARCH_PRICE,
  SET_PROJECT_SEARCH_STATUS,
  GET_ALL_PROJECT_SEARCH_SUCCESS,
  GET_ALL_PROJECT_SEARCH_FAIL,
  GET_ALL_PROJECT_SEARCH,
  UPDATE_MPROJECT_STAUS_SUCCESS,
  UPDATE_MPROJECT_STAUS_FAIL,
  GET_PROJECT_MEDIA,
  GET_PROJECT_LINK,
  GET_PROJECT_GALLERY,
  GET_PROJECT_CONTACT,
  GET_PROJECT_FEATURE,
  DELETE_PROJECT_FEATURE_SUCCESS,
  DELETE_PROJECT_FEATURE_ERROR,
  GET_PROJECT_BUDGET_ALLOCATION,
  GET_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  GET_PROJECT_BUDGET_ALLOCATION_ERROR,
  UPDATE_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  UPDATE_PROJECT_BUDGET_ALLOCATION_ERROR,
  DELETE_PROJECT_BUDGET_SPENT_SUCCESS,
  DELETE_PROJECT_BUDGET_ALLOCATION_SUCCESS,
  DELETE_PROJECT_BUDGET_ALLOCATION_ERROR,
  GET_PROJECT_BUDGET_SPENT,
  GET_PROJECT_BUDGET_SPENT_SUCCESS,
  GET_PROJECT_BUDGET_SPENT_ERROR,
  DELETE_PROJECT_BUDGET_SPENT_ERROR,
  UPDATE_PROJECT_BUDGET_SPENT_SUCCESS,
  UPDATE_PROJECT_BUDGET_SPENT_ERROR,
  GET_PROJECT_CAMP_LEAD,
  GET_PROJECT_CAMP_LEAD_SUCCESS,
  GET_PROJECT_CAMP_LEAD_ERROR,
  DELETE_PROJECT_CAMP_LEAD_SUCCESS,
  DELETE_PROJECT_CAMP_LEAD_ERROR,
  GET_PROJECT_FEATURE_STANDARD,
  GET_PROJECT_FEATURE_STANDARD_SUCCESS,
  GET_PROJECT_FEATURE_STANDARD_ERROR,
  UPDATE_PROJECT_FEATURE_STANDARD_SUCCESS,
  UPDATE_PROJECT_FEATURE_STANDARD_ERROR,
  GET_SETTING_WORKSHEET,
  GET_SETTING_WORKSHEET_SUCCESS,
  GET_SETTING_WORKSHEET_ERROR,
  DELETE_SETTING_WORKSHEET_SUCCESS,
  DELETE_SETTING_WORKSHEET_ERROR,
  GET_ALL_PROJECT_LEAD,
  GET_ALL_PROJECT_LEAD_SUCCESS,
  GET_ALL_PROJECT_LEAD_ERROR,
  GET_ALL_PROJECT_CLIENT,
  GET_ALL_PROJECT_CLIENT_SUCCESS,
  GET_ALL_PROJECT_CLIENT_ERROR,
  ADD_PROJECT_OPEN_HOUSE_FILE,
  ADD_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  ADD_PROJECT_OPEN_HOUSE_FILE_ERROR,
  GET_ALL_PROJECT_OPEN_HOUSE_FILE,
  GET_ALL_PROJECT_OPEN_HOUSE_FILE_ERROR,
  GET_ALL_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  ADD_PROJECT_OPEN_HOUSE,
  ADD_PROJECT_OPEN_HOUSE_SUCCESS,
  ADD_PROJECT_OPEN_HOUSE_ERROR,
  GET_ALL_PROJECT_OPEN_HOUSE,
  GET_ALL_PROJECT_OPEN_HOUSE_ERROR,
  GET_ALL_PROJECT_OPEN_HOUSE_SUCCESS,
  GET_PROJECT_OPEN_HOUSE,
  GET_PROJECT_OPEN_HOUSE_SUCCESS,
  GET_PROJECT_OPEN_HOUSE_ERROR,
  DELETE_PROJECT_OPEN_HOUSE,
  DELETE_PROJECT_OPEN_HOUSE_SUCCESS,
  DELETE_PROJECT_OPEN_HOUSE_ERROR,
  UPDATE_PROJECT_OPEN_HOUSE,
  UPDATE_PROJECT_OPEN_HOUSE_SUCCESS,
  UPDATE_PROJECT_OPEN_HOUSE_ERROR,

  ADD_PROJECT_DOWNLOAD_PRICE_FILE,
  ADD_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  ADD_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  ADD_PROJECT_DOWNLOAD_PRICE,
  ADD_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  ADD_PROJECT_DOWNLOAD_PRICE_ERROR,
  GET_ALL_PROJECT_DOWNLOAD_PRICE,
  GET_ALL_PROJECT_DOWNLOAD_PRICE_ERROR,
  GET_ALL_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  GET_PROJECT_DOWNLOAD_PRICE,
  GET_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  GET_PROJECT_DOWNLOAD_PRICE_ERROR,
  DELETE_PROJECT_DOWNLOAD_PRICE,
  DELETE_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  DELETE_PROJECT_DOWNLOAD_PRICE_ERROR,
  UPDATE_PROJECT_DOWNLOAD_PRICE,
  UPDATE_PROJECT_DOWNLOAD_PRICE_SUCCESS,
  UPDATE_PROJECT_DOWNLOAD_PRICE_ERROR,
  GET_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  GET_PROJECT_OPEN_HOUSE_FILE,
  GET_PROJECT_OPEN_HOUSE_FILE_ERROR,
  DELETE_PROJECT_OPEN_HOUSE_FILE_SUCCESS,
  DELETE_PROJECT_OPEN_HOUSE_FILE_ERROR,
  GET_PROJECT_DOWNLOAD_PRICE_FILE,
  GET_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  GET_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  DELETE_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS,
  DELETE_PROJECT_DOWNLOAD_PRICE_FILE_ERROR,
  GET_PROJECT_WORKSHEET_LEAD,
  GET_PROJECT_WORKSHEET_LEAD_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_ERROR,
  GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL,
  GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_SUCCESS,
  GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_ERROR,
  GET_PROJECT_INVITE,
  GET_PROJECT_INVITE_SUCCESS,
  GET_PROJECT_INVITE_ERROR,
  DELETE_PROJECT_INVITE_SUCCESS,
  DELETE_PROJECT_INVITE_ERROR,
  VIEW_PROJECT_INVITE_ATTENDEES,
  VIEW_PROJECT_INVITE_ATTENDEES_SUCCESS,
  VIEW_PROJECT_INVITE_ATTENDEES_ERROR,
  UPDATE_PROJECT_LINK_SUCCESS,
  UPDATE_PROJECT_LINK_FAIL,
  UPDATE_PROJECT_WORKSHEET_UNIT_SUCCESS,
  UPDATE_PROJECT_WORKSHEET_UNIT_ERROR,
  GET_PROJECT_WORKSHEET_UNIT_INFO,
  GET_PROJECT_WORKSHEET_UNIT_INFO_SUCCESS,
  GET_PROJECT_WORKSHEET_UNIT_INFO_ERROR,
  GET_PROJECT_LEAD_BY_WORKSHEET_STATUS,
  GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_SUCCESS,
  GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_ERROR,
  GET_PROJECT_INVITE_DETAIL,
  GET_PROJECT_INVITE_DETAIL_SUCCESS,
  GET_PROJECT_INVITE_DETAIL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  projectType: [],
  projects: [],
  contacts: [],
  documents: [],
  markets: [],
  projectData: {},
  projectFeatures: [],
  links: [],
  images: [],
  statuses: [],
  complitionYear: '',
  city: '',
  strintingPrice: '',
  status: '',
  projectsss: [],
  allocations: [],
  spents: [],
  campleads: [],
  worksheets: [],
  allProjectOpenHouseData: [],
  allProjectDownloadPriceData: [],
  allProjectDownloadPriceFileData: [],
  files: []

}

const Project = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_PROJECT_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_PROJECT_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        projectType: action.payload,
      }

    case GET_PROJECT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_PROJECT:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      }

    case GET_ALL_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_PROJECT_CONTACT:
      return {
        ...state,
        loading: true
      }
    case GET_PROJECT_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading: false
      }

    case GET_PROJECT_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_PROJECT_MEDIA:
      return {
        ...state,
        loading: true
      }
    case GET_PROJECT_MEDIA_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        loading: false
      }

    case GET_PROJECT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_PROJECT_MARKET_SUCCESS:
      return {
        ...state,
        markets: action.payload,
      }

    case GET_PROJECT_MARKET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_PROJECT_UUID:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_PROJECT_UUID_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
        loading: false
      }

    case GET_ALL_PROJECT_UUID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_PROJECT_FEATURE:
      return {
        ...state,
        loading: true,
      }
    case GET_PROJECT_FEATURE_SUCCESS:
      return {
        ...state,
        projectFeatures: action.payload,
        loading: false,
      }

    case GET_PROJECT_FEATURE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROJECT_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_PROJECT_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case UPDATE_PROJECT_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map(contact =>
          contact.id.toString() === action.payload.id.toString()
            ? { contact, ...action.payload }
            : contact
        ),
      }

    case UPDATE_PROJECT_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload,
      }

    case DELETE_PROJECT_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROJECT_FEATURE_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_PROJECT_FEATURE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case UPDATE_PROJECT_FEATURE_STANDARD_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_PROJECT_FEATURE_STANDARD_ERROR:
      state = { ...state, error: action.payload, loading: false }
    case UPDATE_PROJECT_MARKET_SUCCESS:
      return {
        ...state,
        markets: state.markets.map(market =>
          market.id.toString() === action.payload.id.toString()
            ? { market, ...action.payload }
            : market
        ),
      }

    case UPDATE_PROJECT_MARKET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_MARKET_SUCCESS:
      return {
        ...state,
        market: action.payload,
      }

    case DELETE_PROJECT_MARKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_LINK:
      return {
        ...state,
        loading: true,
      }

    case GET_PROJECT_LINK_SUCCESS:
      return {
        ...state,
        links: action.payload,
        loading: false
      }

    case GET_PROJECT_LINK_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case DELETE_PROJECT_LINK_SUCCESS:
      return {
        ...state,
        link: action.payload,
      }
    case DELETE_PROJECT_LINK_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_GALLERY:
      return {
        ...state,
        loading: true,
      }
    case GET_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        images: action.payload,
        loading: false,
      }

    case GET_PROJECT_GALLERY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        image: action.payload,
      }
    case DELETE_PROJECT_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        statuses: action.payload,
      }

    case GET_ALL_PROJECT_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_PROJECT_SEARCH:
      return {
        ...state,
        loading: true
      }
    case GET_ALL_PROJECT_SEARCH_SUCCESS:
      return {
        ...state,
        projectsss: action.payload,
        loading: false
      }
    case GET_ALL_PROJECT_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_MPROJECT_STAUS_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_MPROJECT_STAUS_FAIL:
      state = { ...state, error: action.payload, loading: false }
    case DELETE_PROJECT_FEATURE_SUCCESS:
      return {
        ...state,
        projectFeature: action.payload,
      }

    case DELETE_PROJECT_FEATURE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_BUDGET_ALLOCATION:
      return {
        ...state,
        loading: true
      }
    case GET_PROJECT_BUDGET_ALLOCATION_SUCCESS:
      return {
        ...state,
        allocations: action.payload,
        loading: false
      }

    case GET_PROJECT_BUDGET_ALLOCATION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_PROJECT_BUDGET_ALLOCATION_SUCCESS:
      return {
        ...state,
        allocations: state.allocations.map(allocation =>
          allocation.id.toString() === action.payload.id.toString()
            ? { allocation, ...action.payload }
            : allocation
        ),
      }

    case UPDATE_PROJECT_BUDGET_ALLOCATION_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_BUDGET_ALLOCATION_SUCCESS:
      return {
        ...state,
        allocation: action.payload,
      }
    case DELETE_PROJECT_BUDGET_ALLOCATION_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_BUDGET_SPENT:
      return {
        ...state,
        loading: true
      }
    case GET_PROJECT_BUDGET_SPENT_SUCCESS:
      return {
        ...state,
        spents: action.payload,
        loading: false
      }

    case GET_PROJECT_BUDGET_SPENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_PROJECT_BUDGET_SPENT_SUCCESS:
      return {
        ...state,
        spents: state.spents.map(spent =>
          spent.id.toString() === action.payload.id.toString()
            ? { spent, ...action.payload }
            : spent
        ),
      }

    case UPDATE_PROJECT_BUDGET_SPENT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_BUDGET_SPENT_SUCCESS:
      return {
        ...state,
        spent: action.payload,
      }
    case DELETE_PROJECT_BUDGET_SPENT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_CAMP_LEAD:
      return {
        ...state,
        loading: true
      }
    case GET_PROJECT_CAMP_LEAD_SUCCESS:
      return {
        ...state,
        leads: action.payload,
        loading: false
      }

    case GET_PROJECT_CAMP_LEAD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case DELETE_PROJECT_CAMP_LEAD_SUCCESS:
      return {
        ...state,
        spent: action.payload,
      }
    case DELETE_PROJECT_CAMP_LEAD_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FEATURE_STANDARD:
      return {
        ...state,
        loading: true,
      }
    case GET_PROJECT_FEATURE_STANDARD_SUCCESS:
      return {
        ...state,
        projectStandardFeatures: action.payload,
        loading: false,
      }

    case GET_PROJECT_FEATURE_STANDARD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_SETTING_WORKSHEET:
      return {
        ...state,
        loading: true,
      }
    case GET_SETTING_WORKSHEET_SUCCESS:
      return {
        ...state,
        worksheets: action.payload,
        loading: false,
      }

    case GET_SETTING_WORKSHEET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_SETTING_WORKSHEET_SUCCESS:
      return {
        ...state,
        worksheet: action.payload,
      }
    case DELETE_SETTING_WORKSHEET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_PROJECT_LEAD:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_PROJECT_LEAD_SUCCESS:
      return {
        ...state,
        allleads: action.payload,
        loading: false,
      }

    case GET_ALL_PROJECT_LEAD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_ALL_PROJECT_CLIENT:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_PROJECT_CLIENT_SUCCESS:
      return {
        ...state,
        allclients: action.payload,
        loading: false,
      }

    case GET_ALL_PROJECT_CLIENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_PROJECT_OPEN_HOUSE_FILE:
      state = {
        ...state,
        loading: true,
      }
      break

    case ADD_PROJECT_OPEN_HOUSE_FILE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case ADD_PROJECT_OPEN_HOUSE_FILE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_ALL_PROJECT_OPEN_HOUSE_FILE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_ALL_PROJECT_OPEN_HOUSE_FILE_SUCCESS:
      state = {
        ...state,
        allProjectOpenHouseFileData: action.payload,
        loading: false,
      }
      break

    case GET_ALL_PROJECT_OPEN_HOUSE_FILE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break


    case ADD_PROJECT_OPEN_HOUSE:
      state = {
        ...state,
        loading: true,
      }
      break

    case ADD_PROJECT_OPEN_HOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case ADD_PROJECT_OPEN_HOUSE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_ALL_PROJECT_OPEN_HOUSE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_ALL_PROJECT_OPEN_HOUSE_SUCCESS:
      state = {
        ...state,
        leads: action.payload,
        loading: false,
      }
      break

    case GET_ALL_PROJECT_OPEN_HOUSE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_PROJECT_OPEN_HOUSE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_OPEN_HOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_PROJECT_OPEN_HOUSE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break


    case DELETE_PROJECT_OPEN_HOUSE:
      state = {
        ...state,
        loading: true,
      }
      break

    case DELETE_PROJECT_OPEN_HOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case DELETE_PROJECT_OPEN_HOUSE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break


    case UPDATE_PROJECT_OPEN_HOUSE:
      state = {
        ...state,
        loading: true,
      }
      break

    case UPDATE_PROJECT_OPEN_HOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case UPDATE_PROJECT_OPEN_HOUSE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      brea
    // Project Download Price
    case ADD_PROJECT_DOWNLOAD_PRICE_FILE:
      state = {
        ...state,
        loading: true,
      }
      break

    case ADD_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case ADD_PROJECT_DOWNLOAD_PRICE_FILE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case ADD_PROJECT_DOWNLOAD_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break

    case ADD_PROJECT_DOWNLOAD_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case ADD_PROJECT_DOWNLOAD_PRICE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_ALL_PROJECT_DOWNLOAD_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_ALL_PROJECT_DOWNLOAD_PRICE_SUCCESS:
      state = {
        ...state,
        allProjectDownloadPriceData: action.payload,
        loading: false,
      }
      break

    case GET_ALL_PROJECT_DOWNLOAD_PRICE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_PROJECT_DOWNLOAD_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_DOWNLOAD_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_PROJECT_DOWNLOAD_PRICE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break


    case DELETE_PROJECT_DOWNLOAD_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break

    case DELETE_PROJECT_DOWNLOAD_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case DELETE_PROJECT_DOWNLOAD_PRICE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case UPDATE_PROJECT_DOWNLOAD_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break

    case UPDATE_PROJECT_DOWNLOAD_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case UPDATE_PROJECT_DOWNLOAD_PRICE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_OPEN_HOUSE_FILE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_OPEN_HOUSE_FILE_SUCCESS:
      state = {
        ...state,
        opfiles: action.payload,
        loading: false,
      }
      break

    case GET_PROJECT_OPEN_HOUSE_FILE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case DELETE_PROJECT_OPEN_HOUSE_FILE_SUCCESS:
      return {
        ...state,
        opfile: action.payload,
      }

    case DELETE_PROJECT_OPEN_HOUSE_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DOWNLOAD_PRICE_FILE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS:
      state = {
        ...state,
        dpfiles: action.payload,
        loading: false,
      }
      break

    case GET_PROJECT_DOWNLOAD_PRICE_FILE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case DELETE_PROJECT_DOWNLOAD_PRICE_FILE_SUCCESS:
      return {
        ...state,
        dpfile: action.payload,
      }
    case DELETE_PROJECT_DOWNLOAD_PRICE_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_WORKSHEET_LEAD:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_WORKSHEET_LEAD_SUCCESS:
      state = {
        ...state,
        worksheetLeads: action.payload,
        loading: false,
      }
      break
    case GET_PROJECT_WORKSHEET_LEAD_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_SUCCESS:
      state = {
        ...state,
        worksheetPurData: action.payload,
        loading: false,
      }
      break
    case GET_PROJECT_WORKSHEET_LEAD_PUR_DETAIL_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_SUCCESS:
      state = {
        ...state,
        worksheetUnitData: action.payload,
        loading: false,
      }
      break
    case GET_PROJECT_WORKSHEET_LEAD_UNIT_DETAIL_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_SUCCESS:
      state = {
        ...state,
        worksheetDocData: action.payload,
        loading: false,
      }
      break
    case GET_PROJECT_WORKSHEET_LEAD_DOC_DETAIL_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_INVITE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_INVITE_SUCCESS:
      state = {
        ...state,
        invitesdata: action.payload,
        loading: false,
      }
      break
    case GET_PROJECT_INVITE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
      case GET_PROJECT_INVITE_DETAIL:
        state = {
          ...state,
          loading: true,
        }
        break
  
      case GET_PROJECT_INVITE_DETAIL_SUCCESS:
        state = {
          ...state,
          inviteDetail: action.payload,
          loading: false,
        }
        break
      case GET_PROJECT_INVITE_DETAIL_ERROR:
        state = { ...state, error: action.payload, loading: false }
        break
    case DELETE_PROJECT_INVITE_SUCCESS:
      return {
        ...state,
        invitedata: action.payload,
      }
    case DELETE_PROJECT_INVITE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case VIEW_PROJECT_INVITE_ATTENDEES:
      state = {
        ...state,
        loading: true,
      }
      break

    case VIEW_PROJECT_INVITE_ATTENDEES_SUCCESS:
      state = {
        ...state,
        attdatas: action.payload,
        loading: false,
      }
      break
    case VIEW_PROJECT_INVITE_ATTENDEES_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case UPDATE_PROJECT_LINK_SUCCESS:
      return {
        ...state,
        links: state.markets.map(link =>
          link.id.toString() === action.payload.id.toString()
            ? { link, ...action.payload }
            : link
        ),
      }
    case UPDATE_PROJECT_LINK_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROJECT_WORKSHEET_UNIT_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_PROJECT_WORKSHEET_UNIT_ERROR:
      state = { ...state, error: action.payload, loading: false }
    case GET_PROJECT_WORKSHEET_UNIT_INFO:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROJECT_WORKSHEET_UNIT_INFO_SUCCESS:
      state = {
        ...state,
        unitdata: action.payload,
        loading: false,
      }
      break
    case GET_PROJECT_WORKSHEET_UNIT_INFO_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

      case GET_PROJECT_LEAD_BY_WORKSHEET_STATUS:
        state = {
          ...state,
          loading: true,
        }
        break
  
      case GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_SUCCESS:
        state = {
          ...state,
          worksheetleads: action.payload,
          loading: false,
        }
        break
      case GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_ERROR:
        state = { ...state, error: action.payload, loading: false }
        break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Project
