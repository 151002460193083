import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import axios from "axios"
import { isEmpty, map } from "lodash";
import { addProject, getProjectUUIDAction, projectUpdate } from "../../../store/projects/actions"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { BlobServiceClient } from '@azure/storage-blob';
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SetupBreadcrumbs from "components/Common/Setup";
import CurrencyDropdown from "components/dropdowns/Other/CurrencyDropdowns";
import UpdateButton from "components/buttons/updateBtn";
import SetupMenu from "./menu/setup-menu";


class ProjectsCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectTypes: [],
      builders: [],
      projectStatus: [],
      files: [],
     
    }
  }

 
 
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };

        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);

        const modifiedAddress = `${streetNumber} ${route}`;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        console.log(modifiedAddress)
        setFieldValue(`fullAddress`, place.formatted_address);
        setFieldValue(`addressLine1`, modifiedAddress);
        setFieldValue(`addressLine2`, city);
        setFieldValue(`city`, city);
        setFieldValue(`province`, province);
        setFieldValue(`postal`, postalCode);
        setFieldValue(`country`, country);
        setFieldValue(`longitude`, longitude);
        setFieldValue(`latitude`, latitude);

      })
      .catch(error => console.error('Error', error));
  };
  
  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction } = this.props;

    if (params && params.projectId) {
      console.log(params);
      ongetProjectUUIDAction(params.projectId);
   
    }

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/ProjectType/allforagent', { 
      params: {
        agentId: parseInt(localStorage.getItem('userId'))
      },    headers: { Authorization: str }
     })
      .then(res => {
        const projectTypes = res.data;
        this.setState({ projectTypes });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/BuilderExt/allforagent', {
        params: {
          agentId: parseInt(localStorage.getItem('userId'))
        }, headers: { Authorization: str }
      })
        .then(res => {
          const builders = res.data;
          this.setState({ builders });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ProjectStatus/allforagent', {
        params: {
          agentId: parseInt(localStorage.getItem('userId'))
        },  headers: { Authorization: str }
      })
        .then(res => {
          const projectStatus = res.data;
          this.setState({ projectStatus });
        })
  }
  async handleFileChange(event, setFieldValue) {
    const file = event.currentTarget.files[0];
    if (file) {
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-06-22T02:18:02Z&st=2024-06-21T18:18:02Z&spr=https,http&sig=Srg7JmJ7EfJQWUNadO188IgXNeCFEzSmQQmctuyjGxA%3D";
      const containerName = "images";
      const storageAccountName = "realtyspace";
      const fileName = `${Date.now()}_${file.name}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });

        //alert('File uploaded successfully!');
        if (setFieldValue) {
          const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
          setFieldValue(`projectLogoLink`, fileUrl);
        }
      } catch (error) {
        console.error('Error uploading file:', error);

      }
    }
  }

  render() {
    const { projectData, onUpdateProject } = this.props;
    
    const complition = [
      /[1-9]/,
      /\d/,
      /\d/,
      /\d/
    ];
    const nofUnit = [
      /[0-9]/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];

    const dateinput = [
      /[0-9]/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,


    ];
    const {
      address,
      errorMessage,
      latitude,
      longitude,
      isGeocoding,
    } = this.state;
 
    return (

      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
            <Row>
              <Col sm='2' className="pe-0" >
                <ProjectSidebar />
              </Col>
              <Col sm='10' className="ps-0">
                <Row className="project-header">
                  <Col sm="7">
                    <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Update Project")} />
                  </Col>
                  <Col sm="4">
                    <h2 className="">{this.props.t(projectData.projectName)}</h2>
                  </Col>
                </Row>
               
                <SetupMenu />
                {/* <RsHelp /> */}
                <Card>
                  <CardBody>

                   

                    <TabContent activeTab={this.state.activeTab} className="text-muted">

                      {!isEmpty(projectData) ? (
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            id: projectData.id,
                            projectId: projectData.projectId,
                            projectName: projectData.projectName,
                            projectType: projectData.projectType,
                            projectBuilder: projectData.projectBuilder,
                            complitionMonth: projectData.complitionMonth,
                            complitionYear: projectData.complitionYear,
                            noOfUnits: projectData.noOfUnits,
                            noOfStories: projectData.noOfStories,
                            minBed: projectData.minBed,
                            maxBed: projectData.maxBed,
                            minArea: projectData.minArea,
                            maxArea: projectData.maxArea,
                            costPerSqFt: projectData.costPerSqFt,
                            minPriceRange: projectData.minPriceRange,
                            maxPriceRange: projectData.maxPriceRange,
                            city: projectData.city,
                            agentWebSite: projectData.agentWebSite,
                            commision: projectData.commision,
                            projectStatus: projectData.projectStatus,
                            siteAddressId: projectData.siteAddressId,
                            officeContactId: projectData.officeContactId,
                            agentId: projectData.agentId,
                            isActive: projectData.isActive,
                            fullAddress: projectData?.projectSiteAddress?.fullAddress,
                            addressLine1: projectData?.projectSiteAddress?.addressLine1,
                            addressLine2: projectData?.projectSiteAddres?.addressLine2,
                            projectNearestIntersection: projectData.projectNearestIntersection,
                            province: projectData?.projectSiteAddress?.province,
                            postal: projectData?.projectSiteAddress?.postal,
                            country: projectData?.projectSiteAddress?.country,
                            longitude: projectData?.projectSiteAddress?.longitude,
                            latitude:  projectData?.projectSiteAddress?.latitude,
                            webSite: '',
                            estimatedOccupancy: projectData.estimatedOccupancy,
                            vipLaunchDate: projectData.vipLaunchDate,
                            publicLaunchDate: projectData.publicLaunchDate,
                            isPublished: projectData.isPublished,
                            projectLogoContent: projectData.projectLogoContent,
                            projectLogoLink: projectData.projectLogoLink,
                            projectCurrencyCode:  projectData.projectCurrencyCode,
                            preConsProjectGuid:projectData.preConsProjectGuid
                          }}
                          validationSchema={Yup.object().shape({
                            projectName: Yup.string().required("This is required"),
                            projectType: Yup.string().required("This is required"),
                            projectStatus: Yup.string().required("This is required"),
                            projectBuilder: Yup.string().required("This is required"),
                            //costPerSqFt: Yup.number().typeError('Enter Valid Number'),
                            //minPriceRange: Yup.number().typeError('Enter Valid Number'),
                           //maxPriceRange: Yup.number().typeError('Enter Valid Number'),

                          })}

                          onSubmit={values => {

                            const updateProject = {
                              id: values.id,
                              projectId: values.projectId,
                              projectName: values.projectName,
                              projectType: parseInt(values.projectType),
                              projectBuilder: parseInt(values.projectBuilder),
                              complitionMonth: values.complitionMonth,
                              complitionYear: parseInt(values.complitionYear),
                              noOfUnits: values.noOfUnits,
                              noOfStories: values.noOfStories,
                              minBed: values.minBed,
                              maxBed: values.maxBed,
                              minArea: parseInt(values.minArea),
                              maxArea: parseInt(values.maxArea),
                              costPerSqFt: parseInt(values.costPerSqFt),
                              minPriceRange: parseInt(values.minPriceRange),
                              maxPriceRange: parseInt(values.maxPriceRange),
                              
                              agentWebSite: values.agentWebSite,
                              commision: parseInt(values.commision),
                              projectStatus: parseInt(values.projectStatus),
                              siteAddressId: values.siteAddressId,
                              officeContactId: values.officeContactId,
                              agentId: parseInt(values.agentId),
                              isActive: true,
                              projectNearestIntersection: values.projectNearestIntersection,
                              
                              fullAddress: values.fullAddress,
                              addressLine1: values.addressLine1,
                              addressLine2: values.addressLine2,
                              province: values.province,
                              postal: values.postal,
                              city: values.city,
                              country: values.country,
                              longitude: values.longitude,
                              latitude: values.latitude,
                              webSite: '',
                              estimatedOccupancy: values.estimatedOccupancy,
                              vipLaunchDate: values.vipLaunchDate,
                              publicLaunchDate: values.publicLaunchDate,
                              isPublished: values.isPublished,
                              projectLogoContent: values.projectLogoContent,
                              projectLogoLink: values.projectLogoLink,
                              projectCurrencyCode:  values.projectCurrencyCode,
                              preConsProjectGuid:values.preConsProjectGuid
                            };
                            //console.log(values);
                            onUpdateProject(updateProject);
                          }
                          }

                        >
                          {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                            <Form
                              className="needs-validation"
                            >
                             
                               
                                <Row>
                                  <Col lg="12">
                                    <h5 className="form-sub-heading"><strong>About</strong></h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <CardText className="mb-0">

                                      <Row>
                                        <Col md="3">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="projectName">
                                              {this.props.t("Project Name *")}
                                            </Label>
                                            <Field
                                              name="projectName"
                                              type="text"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.projectName && touched.projectName
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="projectName"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="fullAddress">
                                              {this.props.t("Project Location")}
                                            </Label>


                                            <PlacesAutocomplete
                                             value={values.fullAddress}
                                             onChange={(value) => setFieldValue('fullAddress', value)}
                                            onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                                          >
                                       {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                  <div>
                                                    <input
                                                      {...getInputProps({
                                                        placeholder: 'Enter Address...',
                                                        className: 'form-control',
                                                      
                                                      })}
                                                    />
                                                    <div className="">
                                                      {loading ? <div>Loading...</div> : null}

                                                      {suggestions.map((suggestion, i) => {
                                                        const className = suggestion.active
                                                          ? 'suggestion-item--active'
                                                          : 'suggestion-item';
                                                        const style = suggestion.active
                                                          ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                                          : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                                        return (
                                                          <div
                                                          key={i}
                                                            {...getSuggestionItemProps(suggestion, {
                                                              style,
                                                            })}
                                                          >
                                                            {suggestion.description}
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                )}
                                              </PlacesAutocomplete>


                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="projectNearestIntersection">
                                              {this.props.t("Nearest Intersection")}
                                            </Label>
                                            <Field
                                              name="projectNearestIntersection"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />

                                            <ErrorMessage
                                              name="projectNearestIntersection"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="builderName">
                                              {this.props.t("Builder Name *")}
                                            </Label>
                                            <Field as="select" name="projectBuilder"
                                              className={
                                                "form-control" +
                                                (errors.projectBuilder && touched.projectBuilder
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={handleChange}>
                                              <option value="">Select</option>
                                              {
                                                this.state.builders
                                                  .map(builder =>
                                                    <option key={builder.id} value={builder.id}>{builder.builderName}</option>
                                                  )
                                              }
                                            </Field>
                                            <ErrorMessage
                                              name="projectBuilder"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="pType">
                                              {this.props.t("Type *")}
                                            </Label>
                                            <Field as="select" name="projectType"
                                              className={
                                                "form-control" +
                                                (errors.projectType && touched.projectType
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={handleChange}>
                                              <option value="">Select</option>
                                              {
                                                this.state.projectTypes
                                                  .map(projectType =>
                                                    <option key={projectType.id} value={projectType.id}>{projectType.projectTypeTitle}</option>
                                                  )
                                              }
                                            </Field>
                                            <ErrorMessage
                                              name="projectType"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="projectStatus">
                                              {this.props.t("Project Status *")}
                                            </Label>
                                            <Field as="select" name="projectStatus"
                                              className={
                                                "form-control" +
                                                (errors.projectStatus && touched.projectStatus
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={handleChange}>
                                              <option value="">Select</option>
                                              {
                                                this.state.projectStatus
                                                  .map(projectStat =>
                                                    <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                                                  )
                                              }
                                            </Field>
                                            <ErrorMessage
                                              name="projectStatus"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="minBed">
                                              {this.props.t("Min. BR")}
                                            </Label>
                                            <Field
                                              name="minBed"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="maxBed">
                                              {this.props.t("Max. BR")}
                                            </Label>
                                            <Field
                                              name="maxBed"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="minArea">
                                              {this.props.t("Min. Sqft")}
                                            </Label>
                                            <Field
                                              name="minArea"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />
                                          </FormGroup>

                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="maxArea">
                                              {this.props.t("Max. Sqft")}
                                            </Label>
                                            <Field
                                              name="maxArea"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="1">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="noOfUnits">
                                              {this.props.t("# of Units")}
                                            </Label>
                                            <Field
                                              name="noOfUnits"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />

                                            <ErrorMessage
                                              name="noOfUnits"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="1">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="noOfStories">
                                              {this.props.t("# Stories")}
                                            </Label>
                                            <Field
                                              name="noOfStories"
                                              className="form-control"
                                              onChange={handleChange}
                                              type="text"
                                            />

                                            <ErrorMessage
                                              name="noOfStories"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="costPerSqFt">
                                              {this.props.t("From Cost/Sq. ft")}
                                            </Label>
                                            <InputGroup>
                                              <div className="input-group-append">
                                                <span className="input-group-text dollarfn">
                                                  <i className="fas fa-dollar-sign" />
                                                </span>
                                              </div>
                                              <Field
                                                name="costPerSqFt"
                                                className={
                                                  "form-control" +
                                                  (errors.costPerSqFt && touched.costPerSqFt
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                onChange={handleChange}
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="costPerSqFt"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>

                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="minPriceRange">
                                              {this.props.t("Min. Price")}
                                            </Label>
                                            <InputGroup>
                                              <div className="input-group-append">
                                                <span className="input-group-text dollarfn">
                                                  <i className="fas fa-dollar-sign" />
                                                </span>
                                              </div>
                                              <Field
                                                name="minPriceRange"
                                                className={
                                                  "form-control" +
                                                  (errors.minPriceRange && touched.minPriceRange
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                onChange={handleChange}
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="minPriceRange"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="maxPriceRange">
                                              {this.props.t("Max. Price")}
                                            </Label>
                                            <InputGroup>
                                              <div className="input-group-append">
                                                <span className="input-group-text dollarfn">
                                                  <i className="fas fa-dollar-sign" />
                                                </span>
                                              </div>
                                              <Field
                                                name="maxPriceRange"
                                                className={
                                                  "form-control" +
                                                  (errors.maxPriceRange && touched.maxPriceRange
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                onChange={handleChange}
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="maxPriceRange"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>

                                        <Col md="2">
                                        <CurrencyDropdown name="projectCurrencyCode" label="Project Currency"   onChange={handleChange} />
                                        </Col>         

                                      </Row>

                                    </CardText>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <h5 className="form-sub-heading"><strong>Importants Dates</strong></h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="2">

                                    <FormGroup className="mb-3">
                                      <Label htmlFor="complitionMonth">
                                        {this.props.t("Compl. Month")}
                                      </Label>
                                      <Field as="select" name="complitionMonth"
                                        className={
                                          "form-control" +
                                          (errors.complitionMonth && touched.complitionMonth
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="Jan">Jan</option>
                                        <option value="Feb">Feb</option>
                                        <option value="Mar">Mar</option>
                                        <option value="Apr">Apr</option>
                                        <option value="May">May</option>
                                        <option value="Jun">Jun</option>
                                        <option value="Jul">Jul</option>
                                        <option value="Aug">Aug</option>
                                        <option value="Sep">Sep</option>
                                        <option value="Oct">Oct</option>
                                        <option value="Nov">Nov</option>
                                        <option value="Dec">Dec</option>
                                      </Field>

                                      <ErrorMessage
                                        name="complition"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">

                                    <FormGroup className="mb-3">
                                      <Label htmlFor="complitionYear">
                                        {this.props.t("Compl. Year")}
                                      </Label>
                                      <Field
                                        name="complitionYear"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={complition}
                                            id="complitionYear"
                                            placeholder="xxxx"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.complitionYear && touched.complitionYear
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name="complitionYear"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="projectEstOccupancy">
                                        {this.props.t("Est. Occupancy")}
                                      </Label>
                                      <Flatpickr
                                        className={
                                          "form-control" +
                                          (errors.estimatedOccupancy && touched.estimatedOccupancy
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder=""
                                        value={values.estimatedOccupancy}
                                        onChange={(value) => setFieldValue('estimatedOccupancy', value[0])}
                                        options={{
                                          altInput: true,
                                          altFormat: "F j, Y",
                                          dateFormat: "Y-m-d"
                                        }}
                                      />

                                      <ErrorMessage
                                        name="estimatedOccupancy"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="vipLaunchDate">
                                        {this.props.t("VIP Launch Date")}
                                      </Label>
                                      <Flatpickr
                                        className={
                                          "form-control" +
                                          (errors.vipLaunchDate && touched.vipLaunchDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder=""
                                        value={values.vipLaunchDate}
                                        onChange={(value) => setFieldValue('vipLaunchDate', value[0])}
                                        options={{
                                          altInput: true,
                                          altFormat: "F j, Y",
                                          dateFormat: "Y-m-d"
                                        }}
                                      />

                                      <ErrorMessage
                                        name="vipLaunchDate"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="projectPublicLaunch">
                                        {this.props.t("Public Launch Date")}
                                      </Label>
                                      <Flatpickr
                                        className={
                                          "form-control" +
                                          (errors.publicLaunchDate && touched.publicLaunchDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder=""
                                        value={values.publicLaunchDate}
                                        onChange={(value) => setFieldValue('publicLaunchDate', value[0])}
                                        options={{
                                          altInput: true,
                                          altFormat: "F j, Y",
                                          dateFormat: "Y-m-d"
                                        }}
                                      />

                                      <ErrorMessage
                                        name="publicLaunchDate"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>


                                <Row>
                                  <Col lg="12">
                                    <h5 className="form-sub-heading"><strong>Other Info</strong></h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="agentWebSite">
                                        {this.props.t("Project Website")}
                                      </Label>
                                      <Field
                                        name="agentWebSite"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="commision">
                                        {this.props.t("Agent Commision %/unit")}
                                      </Label>
                                      <Field
                                        name="commision"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col sm="3">
                                    <div className="form-group mb-3">
                                      <Label htmlFor="file">Project Logo</Label>
                                      <input
                                          type="file"
                                          className="form-control"
                                          onChange={event => this.handleFileChange(event, setFieldValue)}
                                        />
                                    </div>



                                  </Col>
                                  <Col sm="1">

                                    {projectData.projectLogoLink != '' ?
                                      <img src={projectData.projectLogoLink} className="img-thumbnail"></img>
                                      : ''
                                    }
                                  </Col>



                                </Row>

                                <div>

                                {projectData.agentId !== 0 && 
                                projectData.projectId === projectData.preConsProjectGuid ? (
                                  <UpdateButton>{this.props.t("Update")}</UpdateButton>
                                ) : null}
                                </div>

                              
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        <></>
                      )}
                    </TabContent>


                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  apiError: PropTypes.any,
  addProject: PropTypes.func,
  address: PropTypes.any,
  ongetProjectUUIDAction: PropTypes.func,
  match: PropTypes.object,
  projectData: PropTypes.object,
  onUpdateProject: PropTypes.func,
  loading: PropTypes.object,

}
const mapStateToProps = ({ Project }) => (
  {
    projectData: Project.projectData,
    loading: Project.loading

  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProject: (data, history) => dispatch(addProject(data, history)),
  onUpdateProject: (data) => dispatch(projectUpdate(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsCreate)))