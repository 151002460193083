import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CampaignData from "./Campaign-data"
import classnames from "classnames"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { map } from "lodash"
import { Link } from "react-router-dom"
import {
  getProjectUUIDAction, getProjectWorksheetLead, getProjectWorksheetLeadPurDetail, getProjectWorksheetLeadUnitDetail,
  getProjectWorksheetLeadDocDetail, getProjectWorksheetUnitinfo
} from "../../../store/actions"
import { addLeadNoteTab, getNoteLeadTab,  addFollowNoteTab, getFollowLeadTab, 
  getLeadCallLog, addLeadCallLog, addLeadEmailLog, getLeadEmailLog, getAllActLeadTab} from "../../../store/leads/actions"
import FileBase64 from "../../../components/Common/FileBase64";
import Flatpickr from "react-flatpickr"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import Moment from 'react-moment';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import JoditEditor from 'jodit-react';
import MaskedInput from "react-text-mask";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import notes from "../../../assets/images/rsicons/notes.png";
import follow from "../../../assets/images/rsicons/follow-up.png";
import phonelog from "../../../assets/images/rsicons/phone-log.png";
import emaillog from "../../../assets/images/rsicons/email-log.png";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Modal,
  Input,
  Button,
  Tooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Collapse
} from "reactstrap"
import { isSubmitting } from "redux-form";
import { API_URL } from "helpers/app_url";
import RsLink from "components/buttons/rsLink";

class projectSrr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      worksheetLead: '',
      submittedTab: "1",
      manualTab: "1",
      activeTab1: "1",
      activeTab2: "4",
      activeTab: "1",
      verticalActiveTab: "1",
      visible: false,
      lead:"",
      isSubmittedData: false,
      isManualData: false,
      isUnitData: false,
      col1: true,
      files: [],
      accordionState: { col0: true },

    }



    this.toggleSubmittedCanvas = this.toggleSubmittedCanvas.bind(this);
    this.toggleManualCanvas = this.toggleManualCanvas.bind(this);
    this.toggleUnitCanvas = this.toggleUnitCanvas.bind(this);
    this.toggleEmailCanvas = this.toggleEmailCanvas.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.toggleFollowCanvas = this.toggleFollowCanvas.bind(this);
    this.toggleCallLogCanvas = this.toggleCallLogCanvas.bind(this);
    this.toggleEmailLogCanvas = this.toggleEmailLogCanvas.bind(this);
    this.toggleSmsLogCanvas = this.toggleSmsLogCanvas.bind(this);
    this.toggleAddNoteCanvas = this.toggleAddNoteCanvas.bind(this);    
    this.toggleMessageCanvas = this.toggleMessageCanvas.bind(this);  
    this.toggleAddLeadCanvas = this.toggleAddLeadCanvas.bind(this);
  }
  getUnitAllocationStatusId = (a) => {

    if (a == 1)
      return "Waiting for Allocation";
    else if (a == 2)
      return "Not Allocated";
    else if (a == 3)
      return "Allocated";
    else if (a == 4)
      return "Withdrawn by Client";
    else
      return "N/A";
  };
  toggleAccordion = (index) => {
    this.setState((prevState) => ({
      accordionState: {
        ...prevState.accordionState,
        [`col${index}`]: !prevState.accordionState[`col${index}`],
      },
    }));
  };
  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,

    });
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,

    });
  }
  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  }
  getFiles(files) {
    this.setState({ files: files })
    //console.log(files);

  }
  toggleSubmit() {

    this.setState({ isSubmittedData: !this.state.isSubmittedData });

  }
  toggleManual() {

    this.setState({ isManualData: !this.state.isManualData });

  }
  toggleUnit() {

    this.setState({ isUnitData: !this.state.isUnitData });

  }
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  toggleSubmittedCanvas() {
    this.setState({ worksheetLead: '', isSubmittedData: !this.state.isSubmittedData });
    this.toggleSubmit();
  }
  toggleSubmittedCanvas = arg => {
    const worksheetLead = arg;
    const { onGetProjectWorksheetLeadPurDetail, onGetProjectWorksheetLeadUnitDetail, onGetProjectWorksheetLeadDocDetail, onGetProjectWorksheetUnitinfo } = this.props;
    this.setState({
      worksheetLead: {
        id: worksheetLead.id,
        unitStatusId: worksheetLead.unitStatusId,
        projectCampaignLeadId: worksheetLead.projectCampaignLeadId,
      }
    });
    //console.log(worksheetLead);
    onGetProjectWorksheetLeadPurDetail(worksheetLead.id);
    onGetProjectWorksheetLeadUnitDetail(worksheetLead.id);
    onGetProjectWorksheetLeadDocDetail(worksheetLead.id);
    onGetProjectWorksheetUnitinfo(worksheetLead.id)
    this.toggleSubmit();
  };
  toggleManualCanvas() {
    this.setState({ worksheetLead: '', isManualData: !this.state.isManualData });
    this.toggleManual();
  }

  toggleManualCanvas = arg => {
    const worksheetLead = arg;
    this.setState({
      worksheetLead: {
        projectLeadWorkSheetId: worksheetLead.projectLeadWorkSheetId,
        projectCampaignLeadId: worksheetLead.projectCampaignLeadId,

      }
    });
    console.log(worksheetLead.projectLeadWorkSheetId)
    this.toggleManual();
  }


  toggleUnitCanvas() {
    this.setState({ worksheetLead: '', isUnitData: !this.state.isUnitData });
    this.toggleUnit();
  }
  toggleUnitCanvas = arg => {
    const worksheetLead = arg;
    const { onGetProjectWorksheetLeadUnitDetail, onGetProjectWorksheetUnitinfo } = this.props;
    this.setState({
      worksheetLead: {
        id: worksheetLead.id,

      }
    });
    // console.log(worksheetLead.id);
    onGetProjectWorksheetLeadUnitDetail(worksheetLead.id);
    onGetProjectWorksheetUnitinfo(worksheetLead.id)
    this.toggleUnit();
  };
  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction, onGetProjectWorksheetLead } = this.props;

    if (params && params.projectId) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectWorksheetLead(params.projectId, 0);
    }
  }
  removeEmptyPurchasers = (data) => {
    return data.filter(item => item.firstName.trim() !== '' || item.lastName.trim() !== '');
  }
  removeEmptySheetAllocationList = (data) => {
    return data.filter(item => item.insideSqFt !== '' || item.outsideSqFt !== '');
  }
  handleSubmit = (values, { setSubmitting }) => {
    const cleanedData = {
      ...values,
      createProjectLeadWorkSheetPurchaserList: this.removeEmptyPurchasers(values.createProjectLeadWorkSheetPurchaserList),
    };

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': str
    };

    toast.loading("Please wait...");
    axios.put(`${API_URL}ProjectLeadWorkSheet`, cleanedData, { headers })
      .then(response => {
        toast.dismiss();
        if (response.data.success) {
          toast.success("Worksheet Form Saved");
          console.log('successful:', response.data);
        } else {
          toast.warn(response.validationErrors[0]);
        }
      })
      .catch(error => {
        toast.dismiss();
        console.error('Error:', error);
      });
    //console.log(cleanedData);        
    setSubmitting(false);
  }

  handleClearProject = arg => {
    const worksheetLead = arg;
    //console.log(worksheetLead)
    const cleanedData = {
      projectCampaignLeadId: worksheetLead.projectCampaignLeadId,
    };
   
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': str
    };

    toast.loading("Please wait...");
    axios.put(`${API_URL}ProjectWorkSheet/ClearProjectWorkSheet`, cleanedData, { headers })
      .then(response => {
        toast.dismiss();
        if (response.data.success) {
          toast.success("Clear Project Saved");
          console.log('successful:', response.data);
        } else {
          toast.warn(response.validationErrors[0]);
        }
      })
      .catch(error => {
        toast.dismiss();
        console.error('Error:', error);
      });
    //console.log(cleanedData);        

  }
  toggleCanasEmail() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    }));
  }
  toggleEmailCanvas() {
    this.setState({ worksheetLead: "", isEdit: false, isRight: !this.state.isRight });
    this.toggleCanasEmail();
  }
  toggleEmailCanvas = arg => {
    const worksheetLead = arg;
    const { onGetAllActLead } = this.props;
    this.setState({
      worksheetLead: {
        id: worksheetLead.leadId,
      },
      isEdit: true,
    });
    onGetAllActLead(worksheetLead.leadId)
    this.toggleCanasEmail();
  };

  // Short Function
  toggle() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    }));
    
}
toggleFollow() {
  this.setState(prevState => ({
    isFollow: !prevState.isFollow
  }));
  
}
toggleAddNote() {
  this.setState(prevState => ({
    isAddNote: !prevState.isAddNote
  }));
  
}
toggleCallLog() {
  this.setState(prevState => ({
    isCallLog: !prevState.isCallLog
  }));
  
}
toggleWorkSheet() {
  this.setState({ isWorkSheet: !this.state.isWorkSheet });
}
toggleEmailLog() {
  this.setState(prevState => ({
    isEmailLog: !prevState.isEmailLog
  }));
  
}
toggleSmsLog() {
  this.setState(prevState => ({
    isSmsLog: !prevState.isSmsLog
  }));
  
}
toggleCanasEmail() {
  this.setState(prevState => ({
      isRight: !prevState.isRight
  }));
}
toggleEmailCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isRight: !this.state.isRight });
  this.toggleCanasEmail();
}
toggleAddNoteCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isFollow: !this.state.isAddNote });
  this.toggleAddNote();
}
toggleMessageCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isMessage: !this.state.isMessage });
  //this.toggleAddNote();
}
toggleFollowCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isFollow: !this.state.isFollow });
  this.toggleFollow();
}

toggleCallLogCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isCallLog: !this.state.isCallLog });
  this.toggleCallLog();
}

toggleEmailLogCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isEmailLog: !this.state.isEmailLog });
  this.toggleEmailLog();
}
toggleSmsLogCanvas() {
  this.setState({ worksheetLead: "", isEdit: false, isSmsLog: !this.state.isSmsLog });
  this.toggleSmsLog();
}
toggleOpenHouse() {
  this.setState(prevState => ({
    isEditOpenHouse: !prevState.isEditOpenHouse
  }));
}
  toggleOpenHouseCanvas() {
    this.setState({ isEditOpenHouseData: {}, isEditOpenHouse: !this.state.isEditOpenHouse });
    this.toggleOpenHouse();
  }

  toggleOpenHouseCanvas = arg => {
    this.setState({isEditOpenHouseData: arg});
    // onGetNoteLead(camplead.leadId);
    this.toggleOpenHouse();
};

  toggleAddNoteCanvas() {
    this.setState({ camplead: "", isEdit: false, isAddNote: !this.state.isAddNote });
    this.toggleAddNote();
  }


toggleFollowCanvas = arg => {
  const worksheetLead = arg;
  const { onGetFollowLead } = this.props;
  this.setState({
    worksheetLead: {
      leadId: worksheetLead.leadId,
  },
  isEditFollow: true,
  });
  onGetFollowLead(worksheetLead.leadId);
  this.toggleFollow();
};
toggleAddNoteCanvas = arg => {
  const worksheetLead = arg;
  const { onGetNoteLead } = this.props;
  this.setState({
    worksheetLead: {
    leadId: worksheetLead.leadId,
  },
  isEdit: true,
  });
  onGetNoteLead(worksheetLead.leadId);
  this.toggleAddNote();
};

toggleCallLogCanvas = arg => {
  const worksheetLead = arg;
  const { onGetLeadCallLog } = this.props;
  this.setState({
    worksheetLead: {
      leadId: worksheetLead.leadId,
  },
 
  });
  onGetLeadCallLog(worksheetLead.leadId);
  this.toggleCallLog();
};
toggleEmailLogCanvas = arg => {
  const worksheetLead = arg;
  const { onGetLeadEmailLog } = this.props;
  this.setState({
    worksheetLead: {
    leadId: worksheetLead.leadId,
  },
  isEditCall: true,
  });
  onGetLeadEmailLog(worksheetLead.leadId);
  this.toggleEmailLog();
};
toggleSmsLogCanvas = arg => {
  const worksheetLead = arg;
  this.setState({
    worksheetLead: {
      leadId: worksheetLead.id
      },
      
    
  });
  this.toggleSmsLog();
};
  toggleAddLeadCanvas() {
    this.setState({  isAddLead: !this.state.isAddLead });
    //this.toggleAddLead();
  }
  toggleEmailCanvas = arg => {
    //alert('Hi');
    const worksheetLead = arg;
    const { onGetAllActLead } = this.props;
    this.setState({
      worksheetLead: {
        leadId: worksheetLead.leadId,
      },
        isEdit: true,
    });
    onGetAllActLead(worksheetLead.leadId)
    this.toggleCanasEmail();
};
  


  render() {
    const { projectData, worksheetLeads, worksheetPurData, worksheetUnitData, worksheetDocData, unitdata, onGetProjectWorksheetLead, activities, onAddLeadNote, onAddLeadFollowUp, onAddCallLog, onAddEmailLog } = this.props;
    const { leadNotes, leadFollows, calllogs, emaillogs,  } = this.props;
    const { isEdit, isEditFollow, isEditCall, lead } = this.state;
    const worksheetLead = this.state.worksheetLead;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    //console.log(worksheetUnitData);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
            <Row>
              <Col sm='2' className="pe-0" >
                <ProjectSidebar />
              </Col>
              <Col sm='10' className="ps-0">
                <Row className="project-header">
                  <Col sm="7">
                    <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Activity")} />
                  </Col>
                  <Col sm="4">
                    <h2 className="">{this.props.t(projectData.projectName)}</h2>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <Nav tabs className="projectTab projectNav">

                      <NavItem>
                        <Link className="nav-link " to={"/pre-construction/campaign/current-prospects/" + this.props.match.params.projectId}

                        >
                          <span className="d-none d-sm-block">{this.props.t("Leads")}</span>

                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link active" to={"/pre-construction/campaign/srr-filled/" + this.props.match.params.projectId}

                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>

                        </Link>
                      </NavItem>

                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/campaign/client/" + this.props.match.params.projectId}

                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>

                        </Link>
                      </NavItem>

                    </Nav>

                  </Col>

                </Row>
                <Row>

                  <Col lg="12">

                    <Card>
                      <CardBody>

                        <h4 className="card-title mb-4">Suite Request Form </h4>

                        <div className="table-rep-plugin">
                          <div
                            className=""
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <Thead>
                                <Tr>
                                  <Th>{this.props.t("Name")}</Th>

                                  <Th data-priority="2">{this.props.t("Email")}</Th>
                                  <Th data-priority="3">{this.props.t("Date Send ")}</Th>
                                  <Th data-priority="4">{this.props.t("Date Submited")}</Th>
                                  <Th data-priority="4">{this.props.t("Worksheet Status")}</Th>
                                  <Th data-priority="4">{this.props.t("Allotment Status")}</Th>
                                  <Th data-priority="5">{this.props.t("Action")}</Th>

                                </Tr>
                              </Thead>
                              <Tbody>
                                {map(worksheetLeads, (worksheetLead, prokey) => (
                                  <Tr>
                                    <Td className="table-data">
                                      <div className="row">
                                        <div className="col-sm-8" >{worksheetLead.name}</div>
                                       <div className="col-sm-1"><Link onClick={() => this.toggleFollowCanvas(worksheetLead)} href="#"><i className="grid-icon bx bx-user-voice"></i></Link></div>
                                                                <div className="col-sm-1"><Link onClick={() => this.toggleEmailCanvas(worksheetLead)} to="#"><i className="grid-icon bx  bx-timer"></i></Link></div>
                                                                <div className="col-sm-1"><Link onClick={this.toggleMessageCanvas} to="#"><i className="grid-icon  bx bx-message-alt"></i></Link></div>
                                                                <div className="col-sm-1">
                                                                <UncontrolledDropdown>
                                                                      <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                                      <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                                                                      </DropdownToggle>
                                                                      <DropdownMenu className="dropdown-menu-end">
                                                                      <DropdownItem onClick={() => this.toggleAddNoteCanvas(worksheetLead)} href="#"><i className="bx bx-user-voice"></i> Add Note</DropdownItem>
                                                                        <DropdownItem onClick={() => this.toggleCallLogCanvas(worksheetLead)} href="#"><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                                                        <DropdownItem onClick={() => this.toggleEmailLogCanvas(worksheetLead)} href="#"><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>
                                                                        {/*  <DropdownItem onClick={() => this.toggleSmsLogCanvas(data)} href="#"><i className=" bx bx-message-dots"></i> Add SMS log</DropdownItem> */}
                                                                        {/* <DropdownItem onClick={() => this.toggleFollowCanvas(data)} href="#"><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem> */}
                                                                      </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                
                                                                </div>
                                      </div>

                                    </Td>
                                    <Td>{worksheetLead.email} </Td>
                                    <Td>{worksheetLead.filledDate ? <Moment format="D MMM YY">{worksheetLead.filledDate}</Moment> : ''}</Td>
                                    <Td> {worksheetLead.filledDate ? <Moment format="D MMM YY">{worksheetLead.filledDate}</Moment> : ''}</Td>
                                    <Td> {worksheetLead.leadStatusId == 3 ? "Submitted" : "Pending"}</Td>
                                    <Td> {this.getUnitAllocationStatusId(worksheetLead.unitStatusId)}
                                    </Td>

                                    <Td> {worksheetLead.leadStatusId == 3 ? <RsLink className="btn btn-action" iconClass="" onClick={() => this.toggleSubmittedCanvas(worksheetLead)}>Manage</RsLink> : ''}
                                      {/* { worksheetLead.leadStatusId== 3  ?
                                                          <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem href="#" onClick={() => this.toggleSubmittedCanvas(worksheetLead)}>Submited Worksheet</DropdownItem>
                                       
                                        <DropdownItem  href="#" onClick={() => this.toggleManualCanvas(worksheetLead)}>Manual Worksheet</DropdownItem> 
                                        <DropdownItem  href="#" onClick={() => this.toggleUnitCanvas(worksheetLead)}>Unit Status</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                    : "" } */}
                                    </Td>

                                  </Tr>
                                ))}

                              </Tbody>
                            </Table>
                          </div>
                        </div>








                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Offcanvas
              isOpen={this.state.isSubmittedData}
              direction="end"
              toggle={this.toggleSubmittedCanvas}
              style={{ width: 600 }}

            >
              <OffcanvasHeader className="submittedWorksheet" toggle={this.toggleSubmittedCanvas}>
                Submitted Worksheet <span className="float-end"><i className=" bx bx-printer"></i></span>
              </OffcanvasHeader>
              <OffcanvasBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "1",
                      })}
                      onClick={() => {
                        this.toggle1("1");
                      }}
                    >
                      Applicant Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "2",
                      })}
                      onClick={() => {
                        this.toggle1("2");
                      }}
                    >
                      Unit Choices
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "4",
                      })}
                      onClick={() => {
                        this.toggle1("4");
                      }}
                    >
                      Unit Status
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab1 === "3",
                      })}
                      onClick={() => {
                        this.toggle1("3");
                      }}
                    >
                      Documents
                    </NavLink>
                  </NavItem>

                </Nav>
                <TabContent
                  activeTab={this.state.activeTab1}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <div className="tabContent">
                      {map(worksheetPurData, (purData, purkey) => (
                        <>
                          <h5>Purchaser {purkey + 1}</h5>
                          <div className="table-responsive">
                            <Table className="table table-sm m-0">

                              <tbody>
                                <tr>
                                  <th scope="row">Name</th>
                                  <td>{purData.firstName + " " + purData.lastName}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Address</th>
                                  <td>{purData.address}</td>
                                </tr>
                                <tr>
                                  <th scope="row">City</th>
                                  <td>{purData.city}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Province</th>
                                  <td>{purData.province}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Phone</th>
                                  <td>{purData.homePhone}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Cell</th>
                                  <td>{purData.cellPhone}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Email</th>
                                  <td>{purData.email}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Birth Date</th>
                                  <td>{purData.birthDate ? <Moment format="D MMM YY">{purData.birthDate}</Moment> : ''}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Drivers License</th>
                                  <td>{purData.driversLicense}</td>

                                </tr>
                                <tr>
                                  <th scope="row">Occupation</th>
                                  <td>{purData.occupation}</td>
                                </tr>

                              </tbody>
                            </Table>
                          </div>
                          <p>{" "}</p>
                        </>
                      ))}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="tabContent">
                      <div className="table-responsive">
                        <Table className="table table-sm m-0">

                          <tbody>
                            {map(worksheetUnitData, (unitData, unitkey) => (
                              <>
                                <tr>
                                  <th scope="row">Choice {unitkey + 1}</th>
                                  <td>Model: {unitData.optionNumber} {" "} Type: {unitData.optionType}</td>


                                </tr>
                                <tr>
                                  <td colSpan="2">Notes: {unitData.optionNotes}</td>
                                </tr>
                              </>
                            ))}


                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tabId="3">
                    <div className="tabContent">
                      <div className="table-responsive">


                        <h5>Purchaser</h5>
                        <Table className="table table-sm m-0">

                          <tbody>
                            {map(worksheetDocData, (docData, dockey) => (
                              <tr>
                                <th scope="row">{dockey + 1}</th>
                                <td>{docData.documentTitle}</td>
                                <td><a target="_blank" rel="noreferrer" href={docData.documentURL}>View</a></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <p>{" "}</p>



                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <TabPane tabId="4">
                      <div className="tabContent">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            updateViaEmail: unitdata?.updateViaEmail ? true : false,
                            depositPaymentReceived:unitdata?.depositPaymentReceived ? true : false,
                            paymentInfo:unitdata?.paymentInfo ? unitdata.paymentInfo : '',
                            emailContent:unitdata?.emailContent ? unitdata.emailContent : '',
                            id: worksheetLead.id,
                            projectLeadWorkSheetId: worksheetLead.id,
                            unitStatusId: (unitdata?.unitStatusId) ? unitdata.unitStatusId : 1,
                            unitStatusText: (unitdata?.unitStatusText) ? unitdata.unitStatusText : 'Waiting for allocations',
                            unitNote: (unitdata?.unitNote) ? unitdata.unitNote : '',
                            allocationBy: parseInt(localStorage.getItem('userId')),
                            createProjectLeadWorkSheetAllocationList: worksheetUnitData?.map((item) => ({
                              projectLeadWorkSheetId: worksheetLead.id,
                              selectedOptionId: item?.isAllocated ? item?.id : null,
                              selectedOptionText: item?.optionNumber
                                ? `${item.optionNumber}/${item.optionType}`
                                : "",
                              unitCost: item?.unitCost || "", 
                              insideSqFt: item?.insideSqFt || "", 
                              outsideSqFt: item?.outsideSqFt || "", 
                            })) ||  []
                            
                          }}
                          validationSchema={Yup.object().shape({
                            // createProjectLeadWorkSheetAllocationList: Yup.array().of(
                            //   Yup.object().shape({
                            //     selectedOptionId: Yup.string().required("Option ID is required"),
                            //     insideSqFt: Yup.number()
                            //       .typeError("Must be a number")
                            //       .when("selectedOptionId", {
                            //         is: (val) => !!val,
                            //         then: Yup.number().required("Inside sq. ft. is required"),
                            //       }),
                            //     outsideSqFt: Yup.number()
                            //       .typeError("Must be a number")
                            //       .when("selectedOptionId", {
                            //         is: (val) => !!val,
                            //         then: Yup.number().required("Outside sq. ft. is required"),
                            //       }),
                            //     unitCost: Yup.number()
                            //       .typeError("Must be a number")
                            //       .when("selectedOptionId", {
                            //         is: (val) => !!val,
                            //         then: Yup.number().required("Cost is required"),
                            //       }),
                            //   })
                            // ),
                          })}

                          onSubmit={(values) => {
                            const filteredList = this.removeEmptySheetAllocationList(values.createProjectLeadWorkSheetAllocationList);
                            const convertedList = filteredList.map(item => ({
                              ...item,
                              unitCost: parseInt(item.unitCost, 10) || 0,
                              insideSqFt: parseInt(item.insideSqFt, 10) || 0,
                              outsideSqFt: parseInt(item.outsideSqFt, 10) || 0,
                            }));

                            const allocationData = {
                              ...values,
                              createProjectLeadWorkSheetAllocationList: convertedList,
                            };
                            const obj = JSON.parse(localStorage.getItem("authUser"));
                            const token = obj.resultData.bearerToken;
                            const str = 'Bearer ' + token;
                            const headers = {
                              'Content-Type': 'application/json',
                              'Authorization': str
                            };

                            toast.loading("Please wait...");
                            axios.post('https://rscoreapi.azurewebsites.net/api/ProjectLeadWorkSheet/AddProjectLeadWorkSheetAllocation', allocationData, { headers })
                              .then(response => {
                                toast.dismiss();
                                if (response.data.success) {
                                  toast.success("Unit Data Submitted");
                                  onGetProjectWorksheetLead(this.props.match.params.projectId, 0);
                                  this.toggleSubmit();
                                } else {
                                  toast.warn(response.validationErrors[0]);
                                }
                              })
                              .catch(error => {
                                toast.dismiss();
                                console.error('Error:', error);
                              });
                          }}
                        >
                          {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                            <Form
                              className="needs-validation"
                            >
                              <Row>

                                <Col md="12">
                                  <FormGroup className="mb-3">


                                    <Input
                                      type="radio"
                                      id="customRadioInline1"
                                      name="unitStatusId"
                                      className="form-check-input mt-2"
                                      value="1"
                                      checked={values.unitStatusId === 1}
                                      onChange={(e) => {
                                        setFieldValue('unitStatusId', 1);
                                        setFieldValue('unitStatusText', 'Waiting for allocation');
                                      }}
                                    />
                                    <Label
                                      className="form-check-label ms-4"
                                      htmlFor="customRadioInline1"
                                    >
                                      <span className="">Waiting for allocation</span>
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3">


                                    <Input
                                      type="radio"
                                      id="customRadioInline2"
                                      name="unitStatusId"
                                      className="form-check-input mt-2"
                                      value="2"
                                      checked={values.unitStatusId === 2}
                                      onChange={(e) => {
                                        setFieldValue('unitStatusId', 2);
                                        setFieldValue('unitStatusText', 'Unit Not allocated');
                                      }}
                                    />
                                    <Label
                                      className="form-check-label ms-4"
                                      htmlFor="customRadioInline2"
                                    >
                                      <span className="">Unit Not allocated</span>
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3">


                                    <Input
                                      type="radio"
                                      id="customRadioInline3"
                                      name="unitStatusId"
                                      className="form-check-input mt-2"
                                      value="3"
                                      checked={values.unitStatusId === 3}
                                      onChange={(e) => {
                                        setFieldValue('unitStatusId', 3);
                                        setFieldValue('unitStatusText', 'Unit Allocated');
                                      }}
                                    />
                                    <Label
                                      className="form-check-label ms-4"
                                      htmlFor="customRadioInline3"
                                    >
                                      <span className="">Unit Allocated</span>
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3">


                                    <Input
                                      type="radio"
                                      id="customRadioInline4"
                                      name="unitStatusId"
                                      className="form-check-input mt-2"
                                      value="4"
                                      checked={values.unitStatusId === 4}
                                      onChange={(e) => {
                                        setFieldValue('unitStatusId', 4);
                                        setFieldValue('unitStatusText', 'Withdrawn from client');
                                      }}
                                    />
                                    <Label
                                      className="form-check-label ms-4"
                                      htmlFor="customRadioInline4"
                                    >
                                      Withdrawn from client
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {values.unitStatusId !== 1 && (
                                <>
                                  <hr></hr>
                                  {values.unitStatusId !== 2 && values.unitStatusId !== 4 && (
                                    <>
                                      <div id="Selchoice">
                                        <FieldArray name="createProjectLeadWorkSheetAllocationList">
                                          {({ insert, remove, push }) => (
                                            <>
                                              {map(worksheetUnitData, (unitData, unitkey) => (
                                                <Row key={unitkey}>
                                                  <Col md="12">
                                                    <div className="form-check mb-3">
                                                      <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                        //value={unitData.id}
                                                        id={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                        onChange={(e) => {
                                                          const isChecked = e.target.checked;
                                                          const newValue = isChecked ? unitData.id : null;
                                                          const optionText = isChecked ? `${unitData.optionNumber}/${unitData.optionType}` : '';

                                                          setFieldValue(`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`, newValue);
                                                          setFieldValue(`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionText`, optionText);
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        id={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                      >
                                                        {unitData.optionNumber}/{unitData.optionType}
                                                      </label>
                                                    </div>

                                                  </Col>
                                                  <Col md="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}>
                                                        {this.props.t("Inside sq. ft.")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}
                                                        type="text"
                                                        className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey].insideSqFt &&
                                                            touched.createProjectLeadWorkSheetAllocationList &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey].insideSqFt
                                                            ? " is-invalid"
                                                            : ""
                                                          }`}
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}>
                                                        {this.props.t("Outside sq.ft")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}
                                                        type="text"
                                                        onChange={handleChange}
                                                        className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey].outsideSqFt &&
                                                            touched.createProjectLeadWorkSheetAllocationList &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey].outsideSqFt
                                                            ? " is-invalid"
                                                            : ""
                                                          }`}
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}>
                                                        {this.props.t("Cost")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}
                                                        type="text"
                                                        onChange={handleChange}
                                                        className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            errors.createProjectLeadWorkSheetAllocationList[unitkey].unitCost &&
                                                            touched.createProjectLeadWorkSheetAllocationList &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                            touched.createProjectLeadWorkSheetAllocationList[unitkey].unitCost
                                                            ? " is-invalid"
                                                            : ""
                                                          }`}
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                </Row>


                                              ))}
                                            </>
                                          )}
                                        </FieldArray>
                                      </div>
                                      <Row>
                                        <Col sm="12">
                                          <div className="form-check mb-3">
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="depositPaymentReceived"
                                              id="depositPaymentReceived"
                                            />
                                            {" "} <label htmlFor="depositPaymentReceived" className="form-check-label">Deposit payment received</label>
                                          </div>
                                        </Col>
                                        {values.depositPaymentReceived && (
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="paymentInfo">
                                                {this.props.t("Payment info")}
                                              </Label>
                                              <Field
                                                name="paymentInfo"
                                                onChange={handleChange}
                                                as="textarea"
                                                className={
                                                  "form-control" +
                                                  (errors.paymentInfo && touched.paymentInfo
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="paymentInfo"
                                              />
                                              <ErrorMessage
                                                name="paymentInfo"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        )}
                                      </Row>
                                    </>
                                  )}
                                  <Row>
                                    <Col sm="12">
                                      <div className="form-check mb-3">
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="updateViaEmail"
                                          id="updateViaEmail"
                                        />
                                        {" "} <label htmlFor="updateViaEmail" className="form-check-label">Provide update via email</label>
                                      </div>
                                    </Col>
                                    {values.updateViaEmail && (
                                      <Col md="12">
                                        <JoditEditor
                                          value={values.emailContent}
                                          onBlur={(newContent) => {
                                            setFieldValue('emailContent', newContent);
                                          }}
                                          config={{
                                            readonly: false,
                                            placeholder: "Start typing here...",
                                            height: 400,

                                          }}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                  <Row id="noteRow">
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="unitNote">
                                          {this.props.t("Add Note")}
                                        </Label>
                                        <Field
                                          name="unitNote"
                                          onChange={handleChange}
                                          as="textarea"
                                          className={
                                            "form-control" +
                                            (errors.unitNote && touched.unitNote
                                              ? " is-invalid"
                                              : "")
                                          }
                                          id="unitNote"
                                        />
                                        <ErrorMessage
                                          name="unitNote"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>
                            </Form>
                          )}
                        </Formik>


                      </div>
                    </TabPane>
                  </TabPane>
                </TabContent>
                {worksheetLead.unitStatusId != 0 && worksheetLead.unitStatusId != 3 ?
                  <div className="clearfix"><RsLink className="btn-add" iconClass="fas fa-recycle" onClick={() => this.handleClearProject(worksheetLead)}>Clear Worksheet</RsLink></div>
                  : ' '}


              </OffcanvasBody>
            </Offcanvas>


            <Offcanvas
              isOpen={this.state.isManualData}
              direction="end"
              toggle={this.toggleManualCanvas}

            >
              <OffcanvasHeader toggle={this.toggleManualCanvas}>
                Manual Upload
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectLeadWorkSheetId: worksheetLead.projectLeadWorkSheetId,
                    projectCampaignLeadId: worksheetLead.projectCampaignLeadId,
                    campaignProjectId: parseInt(this.props.match.params.id),
                    noOfOptions: 0,
                    isActive: true,
                    worksheetNotes: '',
                    agentId: parseInt(localStorage.getItem('userId')),
                    isParkingRequired: false,
                    isLockerRequired: false,
                    createProjectLeadWorkSheetOptionList: Array.from({ length: 2 }, () => ({ projectLeadWorkSheetId: 0, optionNumber: '', optionType: '', optionNotes: '', agentId: parseInt(localStorage.getItem('userId')), addedBy: '', })),

                    createProjectLeadWorkSheetPurchaserList: Array.from({ length: 2 }, () => ({
                      projectLeadWorkSheetId: 0,
                      firstName: '',
                      lastName: '',
                      address: '',
                      city: '',
                      province: '',
                      postalCode: '',
                      homePhone: '',
                      cellPhone: '',
                      //email: '',
                      //birthDate: new Date(),
                      driversLicense: '',
                      occupation: '',
                      createProjectLeadWorkSheetPurchaserDocumentList: [{ projectLeadWorkSheetId: 0, projectLeadWorkSheetPurchaserId: 0, projectProspectAgentDocumentId: 0, documentTitle: '' }]
                    })),
                  }}
                  validationSchema={Yup.object().shape({
                    createProjectLeadWorkSheetOptionList: Yup.array().of(
                      Yup.object().shape({
                        optionNumber: Yup.string().required('Model Name is required'),
                        optionType: Yup.string().required('Bedroom Type is required'),
                        optionNotes: Yup.string().required('Bedroom Type is required')
                      })
                    ),
                    createProjectLeadWorkSheetPurchaserList: Yup.array().of(
                      Yup.object().shape({
                        firstName: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        lastName: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        address: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        city: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        province: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        postalCode: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        homePhone: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        cellPhone: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        birthDate: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        driversLicense: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        }),
                        occupation: Yup.string().test('is-required', 'This is required', function (value) {
                          return this.options.index !== 0 || (value && value.trim().length > 0);
                        })
                      })
                    )
                  })}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                  }) => (
                    <Form className="needs-validation">

                      <FieldArray name="createProjectLeadWorkSheetOptionList">
                        {({ insert, remove, push }) => (
                          <>
                            {values.createProjectLeadWorkSheetOptionList.map((LeadWorkSheetOptionList, index) => (
                              <Row key={index}>
                                <h4>Choice {index + 1}</h4>
                                <Col sm="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}>
                                      {this.props.t("Model Name")}
                                    </Label>
                                    <Field
                                      name={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                                      type="text"
                                      className={
                                        'form-control' +
                                        (errors.createProjectLeadWorkSheetOptionList?.[index]?.optionNumber && touched.createProjectLeadWorkSheetOptionList?.[index]?.optionNumber
                                          ? ' is-invalid'
                                          : '')
                                      }
                                      placeholder=""
                                      id={`optionNumber${index + 1}`}
                                    />
                                    <ErrorMessage
                                      name={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionType`}>
                                      {this.props.t("Bedroom Type")}
                                    </Label>
                                    <Field
                                      name={`createProjectLeadWorkSheetOptionList.${index}.optionType`}
                                      type="text"
                                      className={
                                        'form-control' +
                                        (errors.createProjectLeadWorkSheetOptionList?.[index]?.optionType && touched.createProjectLeadWorkSheetOptionList?.[index]?.optionType ? ' is-invalid' : '')
                                      }
                                      placeholder=""
                                      id={`optionType${index + 1}`}
                                    />
                                    <ErrorMessage name={`createProjectLeadWorkSheetOptionList.${index}.optionType`} component="div" className="invalid-feedback" />
                                  </FormGroup>
                                </Col>
                                <Col sm="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}>
                                      {this.props.t("Notes")}
                                    </Label>
                                    <Field
                                      name={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}
                                      type="text"
                                      className={
                                        'form-control' +
                                        (errors.createProjectLeadWorkSheetOptionList?.[index]?.optionNotes && touched.createProjectLeadWorkSheetOptionList?.[index]?.optionNotes ? ' is-invalid' : '')
                                      }
                                      placeholder=""
                                      id={`optionNotes${index + 1}`}
                                    />
                                    <ErrorMessage name={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`} component="div" className="invalid-feedback" />
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </FieldArray>

                      <Row>
                        <Col sm="12">
                          <hr />
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label className="form-check-label me-4" htmlFor="isParkingRequired">
                              <span className="h4">Parking</span>
                            </Label>
                            <Field
                              type="checkbox"
                              id="isParkingRequired"
                              name="isParkingRequired"
                              className="form-check-input mt-2"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label className="form-check-label me-4" htmlFor="isLockerRequired">
                              <span className="h4">Locker</span>
                            </Label>
                            <Field
                              type="checkbox"
                              id="isLockerRequired"
                              name="isLockerRequired"
                              className="form-check-input mt-2"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <div className="accordion" id="accordion">
                          <FieldArray name="createProjectLeadWorkSheetPurchaserList">
                            {({ insert, remove, push }) => (
                              <>
                                {values.createProjectLeadWorkSheetPurchaserList.map((purchaser, index) => (
                                  <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                      <button
                                        className="accordion-button fw-medium"
                                        type="button"
                                        onClick={() => this.toggleAccordion(index)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        Purchaser {index + 1}
                                      </button>
                                    </h2>

                                    <Collapse isOpen={this.state.accordionState[`col${index}`]} className="accordion-collapse">
                                      <div className="accordion-body">
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.firstName`}>
                                                {this.props.t("First Name")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.firstName`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.firstName && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.firstName
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.firstName`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.lastName`}>
                                                {this.props.t("Last Name")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.lastName`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.lastName && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.lastName
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.lastName`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.address`}>
                                                {this.props.t("Address")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.address`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.address && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.address
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.address`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.city`}>
                                                {this.props.t("City")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.city`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.city && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.city
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.city`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.province`}>
                                                {this.props.t("Province")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.province`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.province && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.province
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.province`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`}>
                                                {this.props.t("Postal Code")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.postalCode && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.postalCode
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.honePhone`}>
                                                {this.props.t("Home Phone")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.homePhone`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.homePhone && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.homePhone
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.homePhone`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.cellPhone`}>
                                                {this.props.t("Cell Phone")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.cellPhone`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.cellPhone && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.cellPhone
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.cellPhone`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">

                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.email`}>
                                                {this.props.t("Email")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.email`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.email && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.email
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`purchasers.${index}.email`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.birthDate`}>
                                                {this.props.t("Birth Date")}
                                              </Label>
                                              <Flatpickr
                                                className={`form-control${errors.createProjectLeadWorkSheetPurchaserList?.[index]?.birthDate &&
                                                    touched.createProjectLeadWorkSheetPurchaserList?.[index]?.birthDate
                                                    ? ' is-invalid'
                                                    : ''
                                                  }`}
                                                placeholder=""

                                                onChange={(value) =>
                                                  setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.birthDate`, value[0])
                                                }
                                                options={{
                                                  altInput: false,
                                                  altFormat: "F j, Y",
                                                  dateFormat: "Y-m-d"
                                                }}
                                              />

                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.birthDate`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.driversLicense`}>
                                                {this.props.t("Drivers License")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.driversLicense`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.driversLicense && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.driversLicense
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.driversLicense`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.occupation`}>
                                                {this.props.t("Occupation")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.occupation`}
                                                type="text"
                                                className={
                                                  'form-control' +
                                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.occupation && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.occupation
                                                    ? ' is-invalid'
                                                    : '')
                                                }
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetPurchaserList.${index}.occupation`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <FieldArray name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList`}>
                                          {(arrayHelpers) => (
                                            <div>
                                              <h5>Upload</h5>
                                              {purchaser.createProjectLeadWorkSheetPurchaserDocumentList.map((doc, docIndex) => (
                                                <Row key={docIndex}>
                                                  <Col sm="4">
                                                    <FormGroup className="mb-3">
                                                      <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`}>
                                                        {this.props.t("Document Name")}
                                                      </Label>
                                                      <Field
                                                        name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`}
                                                        type="text"
                                                        className={
                                                          "form-control" +
                                                          (errors[`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`] &&
                                                            touched[`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`]
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                        placeholder=""
                                                      />
                                                      <ErrorMessage
                                                        name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col sm="5">
                                                    <FormGroup className="mb-3" id="upload-box">
                                                      <div className="form-group mb-3">
                                                        <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentUrl`}>
                                                          {this.props.t("Upload Document")}
                                                        </Label>
                                                        <FileBase64
                                                          className="form-control"
                                                          multiple={false}
                                                          onDone={(file) => {
                                                            const fieldName = `createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentUrl`;
                                                            arrayHelpers.replace(docIndex, { ...doc, documentUrl: file.base64 });
                                                          }}

                                                        />
                                                      </div>
                                                    </FormGroup>
                                                  </Col>
                                                  <Col sm="2">
                                                    <button type="button" className="btn btn-add" style={{ marginTop: 30 }} onClick={() => arrayHelpers.remove(docIndex)}>
                                                      Remove
                                                    </button>
                                                  </Col>
                                                </Row>
                                              ))}
                                              <button type="button" className="btn btn-add" onClick={() => arrayHelpers.push({ fileName: '', file: '' })}>
                                                Add Document
                                              </button>
                                            </div>
                                          )}
                                        </FieldArray>
                                      </div>
                                    </Collapse>
                                  </div>
                                ))}
                              </>
                            )}
                          </FieldArray>


                        </div>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <hr></hr>
                        </Col>

                        <Col sm="12">
                          <button
                            type="submit"
                            className="btn btn-add w-md  mt-27"

                          >
                            Submit
                          </button>
                        </Col>
                      </Row>

                    </Form>
                  )}
                </Formik>
              </OffcanvasBody>
            </Offcanvas>

            <Offcanvas
              isOpen={this.state.isUnitData}
              direction="end"
              toggle={this.toggleUnitCanvas}

            >
              <OffcanvasHeader toggle={this.toggleUnitCanvas}>
                Upload Unit
              </OffcanvasHeader>
              <OffcanvasBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab2 === "4",
                      })}
                      onClick={() => {
                        this.toggle2("4");
                      }}
                    >
                      Unit Information
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab2 === "5",
                      })}
                      onClick={() => {
                        this.toggle2("5");
                      }}
                    >
                      New Unit
                    </NavLink>
                  </NavItem>


                </Nav>
                <TabContent
                  activeTab={this.state.activeTab2}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="4">
                    <div className="tabContent">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: worksheetLead.id,
                          projectLeadWorkSheetId: worksheetLead.id,
                          unitStatusId: (unitdata?.unitStatusId) ? unitdata.unitStatusId : 1,
                          unitStatusText: (unitdata?.unitStatusText) ? unitdata.unitStatusText : 'Waiting for allocations',
                          unitNote: (unitdata?.unitNote) ? unitdata.unitNote : '',
                          allocationBy: parseInt(localStorage.getItem('userId')),
                          createProjectLeadWorkSheetAllocationList: worksheetUnitData && Array.isArray(worksheetUnitData)
                          ? worksheetUnitData.map((item) => ({
                              selectedOptionId: item?.id || null,
                              selectedOptionText: item?.optionNumber ? `${item.optionNumber}/${item.optionType}` : '',
                              unitCost: item?.unitCost || '',
                              insideSqFt: item?.insideSqFt || '',
                              outsideSqFt: item?.outsideSqFt || '',
                            })) : [],
                        }}
                        validationSchema={Yup.object().shape({
                          createProjectLeadWorkSheetAllocationList: Yup.array().of(
                            Yup.object().shape({
                              selectedOptionId: Yup.string().required('Selected option ID is required'),
                              insideSqFt: Yup.number().when('selectedOptionId', {
                                is: (val) => !!val,
                                then: Yup.number().required('Inside SqFt is required'),
                                otherwise: Yup.number()
                              }),
                              outsideSqFt: Yup.number().when('selectedOptionId', {
                                is: (val) => !!val,
                                then: Yup.number().required('Outside SqFt is required'),
                                otherwise: Yup.number()
                              }),
                              unitCost: Yup.number().when('selectedOptionId', {
                                is: (val) => !!val,
                                then: Yup.number().required('Cost SqFt is required'),
                                otherwise: Yup.number()
                              })
                            })
                          )

                        })}

                        onSubmit={(values) => {
                          const filteredList = this.removeEmptySheetAllocationList(values.createProjectLeadWorkSheetAllocationList);
                          const convertedList = filteredList.map(item => ({
                            ...item,
                            unitCost: parseInt(item.unitCost, 10) || 0,
                            insideSqFt: parseInt(item.insideSqFt, 10) || 0,
                            outsideSqFt: parseInt(item.outsideSqFt, 10) || 0,
                          }));

                          const allocationData = {
                            ...values,
                            createProjectLeadWorkSheetAllocationList: convertedList,
                          };
                          const obj = JSON.parse(localStorage.getItem("authUser"));
                          const token = obj.resultData.bearerToken;
                          const str = 'Bearer ' + token;
                          const headers = {
                            'Content-Type': 'application/json',
                            'Authorization': str
                          };

                          toast.loading("Please wait...");
                          axios.post('https://rscoreapi.azurewebsites.net/api/ProjectLeadWorkSheet/AddProjectLeadWorkSheetAllocation', allocationData, { headers })
                            .then(response => {
                              toast.dismiss();
                              if (response.data.success) {
                                toast.success("Unit Data Submitted");
                                console.log('successful:', response.data);
                              } else {
                                toast.warn(response.validationErrors[0]);
                              }
                            })
                            .catch(error => {
                              toast.dismiss();
                              console.error('Error:', error);
                            });
                        }}
                      >
                        {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            <Row>

                              <Col md="12">
                                <FormGroup className="mb-3">


                                  <Input
                                    type="radio"
                                    id="customRadioInline1"
                                    name="unitStatusId"
                                    className="form-check-input mt-2"
                                    value="1"
                                    checked={values.unitStatusId === 1}
                                    onChange={(e) => {
                                      setFieldValue('unitStatusId', 1);
                                      setFieldValue('unitStatusText', 'Waiting for allocation');
                                    }}
                                  />
                                  <Label
                                    className="form-check-label ms-4"
                                    htmlFor="customRadioInline1"
                                  >
                                    <span className="">Waiting for allocation</span>
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">


                                  <Input
                                    type="radio"
                                    id="customRadioInline2"
                                    name="unitStatusId"
                                    className="form-check-input mt-2"
                                    value="2"
                                    checked={values.unitStatusId === 2}
                                    onChange={(e) => {
                                      setFieldValue('unitStatusId', 2);
                                      setFieldValue('unitStatusText', 'Unit Not allocated');
                                    }}
                                  />
                                  <Label
                                    className="form-check-label ms-4"
                                    htmlFor="customRadioInline2"
                                  >
                                    <span className="">Unit Not allocated</span>
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">


                                  <Input
                                    type="radio"
                                    id="customRadioInline3"
                                    name="unitStatusId"
                                    className="form-check-input mt-2"
                                    value="3"
                                    checked={values.unitStatusId === 3}
                                    onChange={(e) => {
                                      setFieldValue('unitStatusId', 3);
                                      setFieldValue('unitStatusText', 'Unit Allocated');
                                    }}
                                  />
                                  <Label
                                    className="form-check-label ms-4"
                                    htmlFor="customRadioInline3"
                                  >
                                    <span className="">Unit Allocated</span>
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">


                                  <Input
                                    type="radio"
                                    id="customRadioInline4"
                                    name="unitStatusId"
                                    className="form-check-input mt-2"
                                    value="4"
                                    checked={values.unitStatusId === 4}
                                    onChange={(e) => {
                                      setFieldValue('unitStatusId', 4);
                                      setFieldValue('unitStatusText', 'Withdrawn from client');
                                    }}
                                  />
                                  <Label
                                    className="form-check-label ms-4"
                                    htmlFor="customRadioInline4"
                                  >
                                    Withdrawn from client
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            {values.unitStatusId !== 1 && (
                              <>
                                <hr></hr>
                                {values.unitStatusId !== 2 && values.unitStatusId !== 4 && (
                                  <>
                                    <div id="Selchoice">
                                      <FieldArray name="createProjectLeadWorkSheetAllocationList">
                                        {({ insert, remove, push }) => (
                                          <>
                                           
                                           {map(worksheetUnitData, (unitData, unitkey) => {
                                          console.log("unitData:", unitData); // Logs unitData to the console for debugging
                                          return (
                                                                                      
                                              <Row key={unitkey}>
                                                <Col md="12">
                                                  <div className="form-check mb-3">
                                                    <Field
                                                      className={`form-check-input` + unitData.id}
                                                      type="checkbox"
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`}
                                                      value={values.createProjectLeadWorkSheetAllocationList[unitkey].selectedOptionId}
                                                      id={`defaultCheck${unitkey}`}
                                                  
                                                      onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        const newValue = isChecked ? unitData.id : null;
                                                        const optionText = isChecked ? `${unitData.optionNumber}/${unitData.optionType}` : '';

                                                        setFieldValue(`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionId`, newValue);
                                                        setFieldValue(`createProjectLeadWorkSheetAllocationList.${unitkey}.selectedOptionText`, optionText);
                                                      }}

                                                      checked={
                                                        values.createProjectLeadWorkSheetAllocationList &&
                                                        values.createProjectLeadWorkSheetAllocationList[unitkey]?.selectedOptionId === unitData.id
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      id={`defaultCheck${unitkey}`}
                                                    >
                                                      {unitData.optionNumber}/{unitData.optionType}
                                                    </label>
                                                  </div>

                                                </Col>
                                                <Col md="4">
                                                  <FormGroup className="mb-3">
                                                    <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}>
                                                      {this.props.t("Inside sq. ft.")}
                                                    </Label>
                                                    <Field
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}
                                                      type="text"
                                                      className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                          errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                          errors.createProjectLeadWorkSheetAllocationList[unitkey].insideSqFt &&
                                                          touched.createProjectLeadWorkSheetAllocationList &&
                                                          touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                          touched.createProjectLeadWorkSheetAllocationList[unitkey].insideSqFt
                                                          ? " is-invalid"
                                                          : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.insideSqFt`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                  <FormGroup className="mb-3">
                                                    <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}>
                                                      {this.props.t("Outside sq.ft")}
                                                    </Label>
                                                    <Field
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}
                                                      type="text"
                                                      onChange={handleChange}
                                                      className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                          errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                          errors.createProjectLeadWorkSheetAllocationList[unitkey].outsideSqFt &&
                                                          touched.createProjectLeadWorkSheetAllocationList &&
                                                          touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                          touched.createProjectLeadWorkSheetAllocationList[unitkey].outsideSqFt
                                                          ? " is-invalid"
                                                          : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.outsideSqFt`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                  <FormGroup className="mb-3">
                                                    <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}>
                                                      {this.props.t("Cost")}
                                                    </Label>
                                                    <Field
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}
                                                      type="text"
                                                      onChange={handleChange}
                                                      className={`form-control${errors.createProjectLeadWorkSheetAllocationList &&
                                                          errors.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                          errors.createProjectLeadWorkSheetAllocationList[unitkey].unitCost &&
                                                          touched.createProjectLeadWorkSheetAllocationList &&
                                                          touched.createProjectLeadWorkSheetAllocationList[unitkey] &&
                                                          touched.createProjectLeadWorkSheetAllocationList[unitkey].unitCost
                                                          ? " is-invalid"
                                                          : ""
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                      name={`createProjectLeadWorkSheetAllocationList.${unitkey}.unitCost`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </FormGroup>
                                                </Col>
                                              </Row>
                                          )

                                        })}
                                          </>
                                        )}
                                      </FieldArray>
                                    </div>

                                  </>
                                )}
                                <Row id="noteRow">
                                  <Col md="12">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="unitNote">
                                        {this.props.t("Add Note")}
                                      </Label>
                                      <Field
                                        name="unitNote"
                                        onChange={handleChange}
                                        as="textarea"
                                        className={
                                          "form-control" +
                                          (errors.unitNote && touched.unitNote
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="unitNote"
                                      />
                                      <ErrorMessage
                                        name="unitNote"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>






                                </Row>
                              </>
                            )}
                            <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>
                          </Form>
                        )}
                      </Formik>


                    </div>
                  </TabPane>
                  <TabPane tabId="5">
                    <div className="tabContent">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: worksheetLead.id,
                          unitStatusId: 0,
                          unitStatusText: '',
                          unitNote: '',
                          allocationBy: parseInt(localStorage.getItem('userId')),
                          agentId: parseInt(localStorage.getItem('userId')),
                          createProjectLeadWorkSheetOptionList: [{
                            projectLeadWorkSheetId: worksheetLead.id,
                            optionNumber: '',
                            optionType: '',
                            optionNotes: '',
                            agentId: parseInt(localStorage.getItem('userId')),

                          }],
                          createProjectLeadWorkSheetAllocationList: [{
                            projectLeadWorkSheetId: worksheetLead.id,
                            unitCost: '',
                            insideSqFt: '',
                            outsideSqFt: ''
                          }]

                        }}
                        validationSchema={Yup.object().shape({

                          createProjectLeadWorkSheetOptionList: Yup.array().of(
                            Yup.object().shape({
                              optionNumber: Yup.string().required('This is required'),
                              optionType: Yup.string().required('This is required'),
                              optionNotes: Yup.string().required('This is required'),
                            })
                          ),
                          createProjectLeadWorkSheetAllocationList: Yup.array().of(
                            Yup.object().shape({
                              unitCost: Yup.number().required('Unit Cost is required'),
                              insideSqFt: Yup.number().required('Inside SqFt is required'),
                              outsideSqFt: Yup.number().required('Outside SqFt is required'),
                            })
                          ),
                        })}
                        onSubmit={(values) => {
                          //console.log(values);
                          const filterList = values.createProjectLeadWorkSheetAllocationList;
                          const convertedList = filterList.map(item => ({
                            ...item,
                            unitCost: parseInt(item.unitCost, 10) || 0,
                            insideSqFt: parseInt(item.insideSqFt, 10) || 0,
                            outsideSqFt: parseInt(item.outsideSqFt, 10) || 0,

                          }));
                          const allocationData = {
                            ...values,
                            createProjectLeadWorkSheetAllocationList: convertedList,
                          };
                          const obj = JSON.parse(localStorage.getItem("authUser"));
                          const token = obj.resultData.bearerToken;
                          const str = 'Bearer ' + token;
                          const headers = {
                            'Content-Type': 'application/json',
                            'Authorization': str
                          };

                          toast.loading("Please wait...");
                          axios.post('https://rscoreapi.azurewebsites.net/api/ProjectLeadWorkSheet/AddProjectLeadWorkSheetUnit', allocationData, { headers })
                            .then(response => {
                              toast.dismiss();
                              if (response.data.success) {
                                toast.success("New Unit Added");
                                console.log('successful:', response.data);
                              } else {
                                toast.warn(response.validationErrors[0]);
                              }
                            })
                            .catch(error => {
                              toast.dismiss();
                              console.error('Error:', error);
                            });

                          console.log(allocationData);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          values,
                          handleChange,
                          setFieldValue,
                        }) => (
                          <Form className="needs-validation">

                            <Row>
                              <h4>Choice 1</h4>
                              <FieldArray name="createProjectLeadWorkSheetOptionList">
                                {({ insert, remove, push }) => (
                                  <>
                                    {values.createProjectLeadWorkSheetOptionList.length > 0 &&
                                      values.createProjectLeadWorkSheetOptionList.map((option, index) => (
                                        <div className="row" key={index}>
                                          <Col sm="4">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}>
                                                {this.props.t("Model Name")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.createProjectLeadWorkSheetOptionList &&
                                                    errors.createProjectLeadWorkSheetOptionList[index] &&
                                                    errors.createProjectLeadWorkSheetOptionList[index].optionNumber &&
                                                    touched.createProjectLeadWorkSheetOptionList &&
                                                    touched.createProjectLeadWorkSheetOptionList[index] &&
                                                    touched.createProjectLeadWorkSheetOptionList[index].optionNumber
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col sm="4">
                                            <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionType`}>
                                              {this.props.t("Bedroom Type")}
                                            </Label>
                                            <FormGroup className="mb-3">
                                              <Field
                                                name={`createProjectLeadWorkSheetOptionList.${index}.optionType`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.createProjectLeadWorkSheetOptionList &&
                                                    errors.createProjectLeadWorkSheetOptionList[index] &&
                                                    errors.createProjectLeadWorkSheetOptionList[index].optionType &&
                                                    touched.createProjectLeadWorkSheetOptionList &&
                                                    touched.createProjectLeadWorkSheetOptionList[index] &&
                                                    touched.createProjectLeadWorkSheetOptionList[index].optionType
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder=""
                                                id={`createProjectLeadWorkSheetOptionList.${index}.optionType`}
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetOptionList.${index}.optionType`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col sm="4">
                                            <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}>
                                              {this.props.t("Note")}
                                            </Label>
                                            <FormGroup className="mb-3">
                                              <Field
                                                name={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.createProjectLeadWorkSheetOptionList &&
                                                    errors.createProjectLeadWorkSheetOptionList[index] &&
                                                    errors.createProjectLeadWorkSheetOptionList[index].optionNotes &&
                                                    touched.createProjectLeadWorkSheetOptionList &&
                                                    touched.createProjectLeadWorkSheetOptionList[index] &&
                                                    touched.createProjectLeadWorkSheetOptionList[index].optionNotes
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder=""
                                                id={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </div>
                                      ))}
                                  </>
                                )}
                              </FieldArray>
                              <FieldArray name="createProjectLeadWorkSheetAllocationList">
                                {({ insert, remove, push }) => (
                                  <>
                                    {values.createProjectLeadWorkSheetAllocationList.length > 0 &&
                                      values.createProjectLeadWorkSheetAllocationList.map((option, index) => (
                                        <div className="row" key={index}>
                                          <Col sm="4">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${index}.insideSqFt`}>
                                                {this.props.t("Inside sq. ft.")}
                                              </Label>
                                              <Field
                                                name={`createProjectLeadWorkSheetAllocationList.${index}.insideSqFt`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.createProjectLeadWorkSheetAllocationList &&
                                                    errors.createProjectLeadWorkSheetAllocationList[index] &&
                                                    errors.createProjectLeadWorkSheetAllocationList[index].insideSqFt &&
                                                    touched.createProjectLeadWorkSheetAllocationList &&
                                                    touched.createProjectLeadWorkSheetAllocationList[index] &&
                                                    touched.createProjectLeadWorkSheetAllocationList[index].insideSqFt
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id={`createProjectLeadWorkSheetAllocationList.${index}.insideSqFt`}
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetAllocationList.${index}.insideSqFt`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col sm="4">
                                            <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${index}.outsideSqFt`}>
                                              {this.props.t("Outside sq.ft")}
                                            </Label>
                                            <FormGroup className="mb-3">
                                              <Field
                                                name={`createProjectLeadWorkSheetAllocationList.${index}.outsideSqFt`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.createProjectLeadWorkSheetAllocationList &&
                                                    errors.createProjectLeadWorkSheetAllocationList[index] &&
                                                    errors.createProjectLeadWorkSheetAllocationList[index].outsideSqFt &&
                                                    touched.createProjectLeadWorkSheetAllocationList &&
                                                    touched.createProjectLeadWorkSheetAllocationList[index] &&
                                                    touched.createProjectLeadWorkSheetAllocationList[index].outsideSqFt
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder=""
                                                id={`createProjectLeadWorkSheetAllocationList.${index}.outsideSqFt`}
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetAllocationList.${index}.outsideSqFt`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col sm="4">
                                            <Label htmlFor={`createProjectLeadWorkSheetAllocationList.${index}.unitCost`}>
                                              {this.props.t("Cost")}
                                            </Label>
                                            <FormGroup className="mb-3">
                                              <Field
                                                name={`createProjectLeadWorkSheetAllocationList.${index}.unitCost`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.createProjectLeadWorkSheetAllocationList &&
                                                    errors.createProjectLeadWorkSheetAllocationList[index] &&
                                                    errors.createProjectLeadWorkSheetAllocationList[index].unitCost &&
                                                    touched.createProjectLeadWorkSheetAllocationList &&
                                                    touched.createProjectLeadWorkSheetAllocationList[index] &&
                                                    touched.createProjectLeadWorkSheetAllocationList[index].unitCost
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder=""
                                                id={`createProjectLeadWorkSheetAllocationList.${index}.unitCost`}
                                              />
                                              <ErrorMessage
                                                name={`createProjectLeadWorkSheetAllocationList.${index}.unitCost`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </FormGroup>
                                          </Col>
                                        </div>
                                      ))}
                                  </>
                                )}
                              </FieldArray>
                            </Row>

                            <Row>
                              <Col sm="12">
                                <hr></hr>
                              </Col>

                              <Col sm="12">
                                <button
                                  type="submit"
                                  className="btn btn-danger danger w-md  mt-27"
                                >
                                  Submit
                                </button>
                              </Col>
                            </Row>

                          </Form>
                        )}
                      </Formik>
                    </div>
                  </TabPane>



                </TabContent>


              </OffcanvasBody>
            </Offcanvas>
            <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleEmailCanvas}
          
        >
          <OffcanvasHeader toggle={this.toggleEmailCanvas}>
            All Activities
          </OffcanvasHeader>
          <OffcanvasBody>
          <ul className="verti-timeline list-unstyled">
                                        {map(
                                          activities,
                                          (allactivity, allactkey) => (
                                            <li
                                              className="event-list eventlist"
                                              key={"_allact_" + allactkey}
                                            >
                                              <div className="event-timeline-dot">
                                                {(() => {
                                                  if (
                                                    allactivity.activitySubType ==
                                                      8 ||
                                                    allactivity.activitySubType ==
                                                      9 ||
                                                    allactivity.activitySubType ==
                                                      7
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={emaillog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                      3 ||
                                                    allactivity.activitySubType ==
                                                      4 ||
                                                    allactivity.activitySubType ==
                                                      5
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={phonelog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                    10
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={follow} />
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <img src={notes} />
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                  <h5 className="font-size-14">
                                                    <Moment format="D MMM YY">
                                                      {allactivity.addedDate}
                                                    </Moment>
                                                  </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <h5 className="font-size-14">
                                                    {
                                                      localization[
                                                        "ActivitySubType_" +
                                                          allactivity.activityType +
                                                          "_" +
                                                          allactivity.activitySubType
                                                      ]
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="exNote">
                                                {allactivity.activityRemarks}
                                              </div>
                                            </li>
                                          )
                                        )}
          </ul>
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleEmailCanvas}
          
        >
          <OffcanvasHeader toggle={this.toggleEmailCanvas}>
            All Activities
          </OffcanvasHeader>
          <OffcanvasBody>
          <ul className="verti-timeline list-unstyled">
                                        {map(
                                          activities,
                                          (allactivity, allactkey) => (
                                            <li
                                              className="event-list eventlist"
                                              key={"_allact_" + allactkey}
                                            >
                                              <div className="event-timeline-dot">
                                                {(() => {
                                                  if (
                                                    allactivity.activitySubType ==
                                                      8 ||
                                                    allactivity.activitySubType ==
                                                      9 ||
                                                    allactivity.activitySubType ==
                                                      7
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={emaillog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                      3 ||
                                                    allactivity.activitySubType ==
                                                      4 ||
                                                    allactivity.activitySubType ==
                                                      5
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={phonelog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                    10
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={follow} />
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <img src={notes} />
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                  <h5 className="font-size-14">
                                                    <Moment format="D MMM YY">
                                                      {allactivity.addedDate}
                                                    </Moment>
                                                  </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <h5 className="font-size-14">
                                                    {
                                                      localization[
                                                        "ActivitySubType_" +
                                                          allactivity.activityType +
                                                          "_" +
                                                          allactivity.activitySubType
                                                      ]
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="exNote">
                                                {allactivity.activityRemarks}
                                              </div>
                                            </li>
                                          )
                                        )}
          </ul>
          </OffcanvasBody>
        </Offcanvas>

     
        <Offcanvas
          isOpen={this.state.isAddNote}
          direction="end"
          toggle={this.toggleAddNoteCanvas}
        >
          <OffcanvasHeader toggle={this.toggleAddNoteCanvas}>
            Add Notes
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: worksheetLead.leadId,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                   
                  onAddLeadNote(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleAddNote();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Add Note")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>
      
        <Offcanvas
          isOpen={this.state.isCallLog}
          direction="end"
          toggle={this.toggleCallLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleCallLogCanvas}>
            Call Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: worksheetLead.leadId


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddCallLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleCallLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />  

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="callNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>               
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isFollow}
          direction="end"
          toggle={this.toggleFollowCanvas}

        >
          <OffcanvasHeader toggle={this.toggleFollowCanvas}>
            Follow Us
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                refId: worksheetLead.leadId,
                addNote: (this.state && this.state.addNote) || "",
                nextFollowupDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEditFollow) {
                   //console.log(values)
                  onAddLeadFollowUp(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleFollow();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">

                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb15">

                        <Label htmlFor="logDate">
                          Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.nextFollowupDate && touched.nextFollowupDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.nextFollowupDate}
                          onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (follLog, follLogkey) => (

                    <li className="event-list eventlist" key={"_note_" + follLogkey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{follLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{follLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                          
            
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isEmailLog}
          direction="end"
          toggle={this.toggleEmailLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailLogCanvas}>
            Email Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType:'',
                addedDate: new Date(),
                refId: worksheetLead.leadId  

              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddEmailLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleEmailLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent" onChange={handleChange} />
                          Email Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Received" onChange={handleChange} />
                          Email Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced" onChange={handleChange} />
                          Email Bounced
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="remarks">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="remarks"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="remarks"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emaillogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isSmsLog}
          direction="end"
          toggle={this.toggleSmsLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleSmsLogCanvas}>
            SMS Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                logDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={this.handleLeadEmailSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="SMS Sent" onChange={handleChange} />
                          SMS Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Received" onChange={handleChange} />
                          SMS Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Bounced" onChange={handleChange} />
                          SMS Bounced
                        </label>

                      </div>

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="logDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.logDate && touched.logDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.logDate}
                          onChange={(value) => setFieldValue('logDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="smsNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="smsNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="smsNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
   <Offcanvas
          isOpen={this.state.isMessage}
          direction="end"
          toggle={this.toggleMessageCanvas}
        >
          <OffcanvasHeader toggle={this.toggleMessageCanvas}>
            Message
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                //refId: lead.leadId,
                addMessage: (this.state && this.state.addMessage) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addMessage: Yup.string().required("This is Required")
              })}

              onSubmit=""
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Send Message")}
                        </Label>
                        <textarea
                          name="addMessage"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addMessage && touched.addMessage
                              ? " is-invalid"
                              : "")
                          }
                          id="addMessage"
                        />
                        <ErrorMessage
                          name="addMessage"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Send")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Message History</div>
                              
          </OffcanvasBody>
        </Offcanvas>

          </Container>
        </div>


      </React.Fragment>
    )
  }
}

projectSrr.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  loading: PropTypes.object,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  className: PropTypes.any,
  worksheetLeads: PropTypes.array,
  onGetProjectWorksheetLead: PropTypes.func,
  onGetProjectWorksheetLeadPurDetail: PropTypes.func,
  onGetProjectWorksheetLeadUnitDetail: PropTypes.func,
  onGetProjectWorksheetLeadDocDetail: PropTypes.func,
  worksheetPurData: PropTypes.array,
  worksheetUnitData: PropTypes.array,
  worksheetDocData: PropTypes.array,
  onGetProjectWorksheetUnitinfo: PropTypes.func,
  unitdata: PropTypes.object,
  onGetAllActLead: PropTypes.func,
  activities: PropTypes.array,

  onAddLeadNote: PropTypes.func,
  onGetNoteLead:PropTypes.func,
  leadNotes:PropTypes.array,
  onGetFollowLead:PropTypes.func,
  leadFollows:PropTypes.array,
  onAddLeadFollowUp:PropTypes.func,
  onGetLeadEmailLog:PropTypes.func,
  onGetLeadCallLog:PropTypes.func,
  calllogs:PropTypes.array,
  emaillogs:PropTypes.array,
  onAddCallLog:PropTypes.func,
  onAddEmailLog:PropTypes.func
}
const mapStateToProps = ({ Project, leads }) => (
  {
    projectData: Project.projectData,
    worksheetLeads: Project.worksheetLeads,
    loading: Project.loading,
    activities: leads.activities,
    worksheetPurData: Project.worksheetPurData,
    worksheetUnitData: Project.worksheetUnitData,
    worksheetDocData: Project.worksheetDocData,
    unitdata: Project.unitdata,
    leadNotes: leads.leadNotes,
    leadFollows: leads.leadFollows,
    calllogs:leads.calllogs,
    emaillogs:leads.emaillogs,
  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onGetProjectWorksheetLead: (projectId, statusId) => dispatch(getProjectWorksheetLead(projectId, statusId)),
  onGetProjectWorksheetLeadPurDetail: (id) => dispatch(getProjectWorksheetLeadPurDetail(id)),
  onGetProjectWorksheetLeadUnitDetail: (id) => dispatch(getProjectWorksheetLeadUnitDetail(id)),
  onGetProjectWorksheetLeadDocDetail: (id) => dispatch(getProjectWorksheetLeadDocDetail(id)),
  onGetProjectWorksheetUnitinfo: (id) => dispatch(getProjectWorksheetUnitinfo(id)),
  
  onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
  onAddLeadNote: (data) => dispatch(addLeadNoteTab(data)),
  onGetNoteLead: (leadId) => dispatch(getNoteLeadTab(leadId)),
  onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
  onAddLeadFollowUp:(data) => dispatch(addFollowNoteTab(data)),
  onAddCallLog:(data) => dispatch(addLeadCallLog(data)),
  onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
  onAddEmailLog:(data) => dispatch(addLeadEmailLog(data)),
  onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
  onGetAllActLead: (leadId) => dispatch(getAllActLeadTab(leadId)),


});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectSrr)))
