import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import { getProjectUUIDAction} from "../../../store/projects/actions"
import {getMasterTemp} from "../../../store/templates/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import email2 from "../../../assets/img/project-1.jpg"
import { map } from "lodash"
import axios from "axios"
import { API_URL } from "../../../helpers/app_url";
import Moment from 'react-moment';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import et1 from "../../../assets/img/email-1.png"
import et2 from "../../../assets/img/email-2.png"
import et3 from "../../../assets/img/email-3.png"
import et4 from "../../../assets/img/email-4.png"
import createNew from "../../../assets/img/new-email.png"
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    CardHeader,
    CardFooter
  } from "reactstrap"
import defalutImg from "../../../assets/img/250.png"
class projectMail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            masterTemplates:[],
            campaigns:[],
            used:[],
            customTemplates:[],
            reports: [
              { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
              {
                title: "# Prospects",
                iconClass: "bx-archive-in",
                description: "6",
              },
              {
                title: "# SRR",
                iconClass: "bx-purchase-tag-alt",
                description: "5",
              },
              {
                title: "# Clients",
                iconClass: "bx-purchase-tag-alt",
                description: "2",
              },
            ]
        }       
      }

     
        
      componentDidMount() {

        const { match: { params }, ongetProjectUUIDAction, onGetMasterTemp} = this.props;
    
        if (params && params.projectId) {
          ongetProjectUUIDAction(params.projectId);
          onGetMasterTemp(localStorage.getItem('userId'));
          const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        axios.get(API_URL + 'ProjectEmailCampaign/allProjectEmailCampaigns', {
          params: {
              agentId: localStorage.getItem('userId'),
              projectId: this.props.projectData.projectGuid,   
          },
          headers: { Authorization: str }
        })
        .then(res => {
            const campaigns = res.data;
            this.setState({ campaigns });
        })
        .catch(error => {
            // Handle error here
            console.error('Error fetching data:', error);
        }),
          axios.get('https://rscoreapi.azurewebsites.net/api/ProjectMasterEmailTemplate/alltemplates', {
             headers: { Authorization: str }
        })
            .then(res => {
                const masterTemplates = res.data;
                this.setState({ masterTemplates });
            }),
          axios.get('https://rscoreapi.azurewebsites.net/api/ProjectEmailTemplate/alltemplates', {
            params: {
                agentId: parseInt(localStorage.getItem("userId")),
            }, headers: { Authorization: str }
        })
            .then(res => {
                const customTemplates = res.data;
                this.setState({ customTemplates });
            })
          axios.get(API_URL + 'ProjectEmailCampaign/allProjectEmailCampaignByProjectIdUniqueCurrentlyUsed', {
          params: {
           agentId: localStorage.getItem('userId'),
           projectId : params.id,   
          }, headers: { Authorization: str }
          })
          .then(res => {
          const used = res.data;
          this.setState({ used });
          })
        }

        
      }  


      render() {
        const { projectData, templates } = this.props;
        //console.log(templates);
        //console.log(this.state.customTemplates);

        return (
          <React.Fragment>
              <div className="page-content project-page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Email Campaigns")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
                <Row>
                   
                   <Col lg="12">
                   
                       <Card>
                       <CardBody>
                      
                       {/* <h4 className="card-title mb-4">All Mail Campaign</h4> */}


                                             
                <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={this.state.campaigns} paginator rows={5} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  <Column  field="date" header="Date" body={(rowData) => (
                      <Moment format="D MMM YY">{rowData.createdDate}</Moment>
                  )}></Column>
                  <Column  field="title" header="Title" body={(rowData) => (
                         rowData.emailCampaignTitle
                  )}></Column>
                   <Column  field="target" header="Target" body={(rowData) => (
                        
                         rowData.emailCampaignTargetTypeTitle ? rowData.emailCampaignTargetTypeTitle +"-"+ rowData.emailCampaignTargetTypeSubTitle : ''
                  )}></Column>
                  <Column  field="template" header="Email Template" body={(rowData) => (
                         rowData.emailCampaignTemplateTitle
                  )}></Column>
                  <Column  field="status" header="Status" body={(rowData) => (
                         rowData.status
                  )}></Column>
                  <Column  field="action" header="Action" body={(rowData) => (
                      <Link to="#" className="">View</Link>
                  )}></Column>
              </DataTable>
                              
                               
                              
                              
                             
                          
                       </CardBody>
                       </Card>
                   </Col>
               </Row>     
               <Row>
                   
                   <Col lg="12">
                   
                      
                      
                       
                       <Row>
                   
                   <Col lg="12">
               
                      
                      
                      
                               
         
             <Row>
                   
                    <Col lg="6">
                
                        <Card>
                        <CardBody>
                       
                       
                                <h4 className="card-title mb-4">Available Themes</h4>
                         
            

            <Row className="mb20">
            {
  this.state.masterTemplates.map(masterTemplate => {
    const imageMap = {
      2: et1,
      3: et2,
      4: et3,
      1: et4
    };

    return (
      <Col sm="4" className="mb20" key={masterTemplate.id}>
        <Card>
          <CardBody className="p-0">
          <Link 
              to={`/pre-construction/mail-editor/${this.props.match.params.projectId}/${masterTemplate.id}`} 
              className="previewClass card-link float-end tempLink"
            > <img 
              src={imageMap[masterTemplate.id] || et1}
              className="img-fluid" 
              alt={masterTemplate.templateTitle} 
            /></Link>
          </CardBody>
          
        </Card>
      </Col>
    );
  })
}
            </Row>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                
                        <Card>
                        <CardBody>
                       
                       
                                <h4 className="card-title mb-4">Custom Themes</h4>
                                <Row className="mb20">
                                <Col sm="4" className="mb20">
                               <Card>
                              <CardBody className="p-0">
                              <Link 
                                  to={`/pre-construction/new-mail-editor/${this.props.match.params.projectId}`} 
                                  className="previewClass card-link float-end tempLink"
                                > <img 
                                  src={createNew}
                                  className="img-fluid" 
                                 
                                /></Link>
                              </CardBody>
                              
                            </Card>
                              </Col>
                                {
                            this.state.customTemplates
                                .map(customTemplate =>
                    <Col sm="4" className="mb20" key={customTemplate.id}>
              
              <Card>
            
             <CardBody className="p-0">
             <Link to={"/pre-construction/mail-editor/" + this.props.match.params.projectId + "/" + customTemplate.id}   className="card-link  tempLink">
             <div className="image-container">
                <span className="template-title">{customTemplate.templateTitle}</span>
                <img 
                  src={defalutImg} 
                  className="img-fluid" 
                  alt={customTemplate.templateTitle} 
                />
              </div>
             
             </Link>
                
            </CardBody>
              
              </Card>
              </Col>
            )}
            </Row>
                        </CardBody>
                        </Card>
                        </Col>

                  
                </Row>                    
                       
                   </Col>
               </Row>  
                   </Col>
               </Row> 
               </Col>
               </Row>
              
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectMail.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    className: PropTypes.any,
    onGetMasterTemp:PropTypes.func,
    templates:PropTypes.array
  }
  const mapStateToProps = ({ Project, Template }) => (
    {
      projectData: Project.projectData,
      templates: Template.templates,
      loading:Project.loading
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    onGetMasterTemp:(agentId) => dispatch(getMasterTemp(agentId))
  });
  
  export default connect( mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectMail)))
