import {
    ADD_BUILDER,
    ADD_BUILDER_SUCCESS,
    ADD_BUILDER_ERROR,
    GET_BUILDER,
    GET_BUILDER_DETAIL_SUCCESS,
    GET_BUILDER_DETAIL_FAIL,
    GET_BUILDER_DETAIL,
    GET_BUILDER_FAIL,
    ADD_BUILDER_DEPART,
    ADD_BUILDER_DEPART_SUCCESS,
    ADD_BUILDER_DEPART_ERROR,
    GET_BUILDER_SUCCESS,
    DELETE_BUILDER,
    DELETE_BUILDER_SUCCESS,
    DELETE_BUILDER_FAIL,
    GET_BUILDER_PROJECT,
    GET_BUILDER_PROJECT_SUCCESS,
    GET_BUILDER_PROJECT_FAIL,
    UPDATE_BUILDER,
    UPDATE_BUILDER_SUCCESS,
    UPDATE_BUILDER_ERROR
  } from "./actionTypes"
  
  export const addBuilder = (builder, history) => {
    return {
      type: ADD_BUILDER,
      payload: { builder, history },
    }
  }
  
  export const addSuccess = builder => {
    return {
      type: ADD_BUILDER_SUCCESS,
      payload: builder,
    }
  }

  
  export const builderapiError = error => {
    return {
      type: ADD_BUILDER_ERROR,
      payload: error,
    }
  }


  export const getBuilders = (agentId) => ({
    type: GET_BUILDER,
    agentId
  })
  
  export const getBuildersSuccess = builders => ({
    type: GET_BUILDER_SUCCESS,
    payload: builders,
  })
  
  export const getBuildersFail = error => ({
    type: GET_BUILDER_FAIL,
    payload: error,
  })
  
  export const getBuilderDetail = builderId => ({
    type: GET_BUILDER_DETAIL,
    builderId,
  })
  
  export const getBuilderDetailSuccess = builderData => ({
    type: GET_BUILDER_DETAIL_SUCCESS,
    payload: builderData,
  })
  
  export const getBuilderDetailFail = error => ({
    type: GET_BUILDER_DETAIL_FAIL,
    payload: error,
  })


  export const addBuilderDepart = (depart, history) => {
    return {
      type: ADD_BUILDER_DEPART,
      payload: { depart, history },
    }
  }
  
  export const addDepartSuccess = depart => {
    return {
      type: ADD_BUILDER_DEPART_SUCCESS,
      payload: depart,
    }
  }

  
  export const apiDepartError = error => {
    return {
      type: ADD_BUILDER_DEPART_ERROR,
      payload: error,
    }
  }


  export const deleteBuilder = id => ({
    type: DELETE_BUILDER,
    payload: id,
  })
  
  export const deleteBuilderSuccess = builder => ({
    type: DELETE_BUILDER_SUCCESS,
    payload: builder,
  })
  
  export const deleteBuilderFail = error => ({
    type: DELETE_BUILDER_FAIL,
    payload: error,
  })

  export const getBuilderProject = (builderId, agentId) => ({
    type: GET_BUILDER_PROJECT,
    payload : {builderId, agentId}
  })
  
  export const getBuilderProjectSuccess = builderProjects => ({
    type: GET_BUILDER_PROJECT_SUCCESS,
    payload: builderProjects,
  })
  
  export const getBuilderProjectFail = error => ({
    type: GET_BUILDER_PROJECT_FAIL,
    payload: error,
  })


  export const updateBuilder =  builder => {
    //console.log(appointment)
    return {
      type: UPDATE_BUILDER,
      payload: builder,
    }
  }
  
  export const updateBuilderSuccess = builder => {
    return {
      type: UPDATE_BUILDER_SUCCESS,
      payload: builder,
    }
  }
  
  export const updateBuilderError = error => {
    return {
      type: UPDATE_BUILDER_ERROR,
      payload: error,
    }
  }